.furniture-date-time-picker-wrapper div:first-child {
  height: 100%;
  position: static;
}

.furniture-date-time-picker {
  outline: 0;
  height: 100%;
  width: 100%;
  border: none;
}

.furniture-date {
  padding: $sp-16 $sp-8;
  border: 1px solid $color-b-12;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  input:disabled {
    background: transparent;
  }
}

.furniture-clear-date-picker {
  max-height: 24px;
}
