h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-b-primary;
  margin-top: $sp-24;
  margin-bottom: $sp-16;
}

.furniture-text {
  line-height: 1.3;

  &.big {
    font-weight: 700;
    font-size: 18px;
  }

  &.info {
    font-size: 14px;
    color: $color-b-secondary;
  }

  &.warning {
    color: $color-destructive-ruby;
  }

  &.subheading {
    font-style: italic;
    font-size: 16px;
    padding-top: $sp-8;
    font-weight: 400;
    color: $color-b-secondary;
  }

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.furniture-text-weight-light {
    font-weight: 300;
  }

  &.furniture-text-weight-heavy {
    font-weight: 600;
  }
}

.furniture-heading {
  &.page {
    font-size: 24px;
    margin-top: 0;
  }

  &.section {
    font-size: 20px;
  }

  &.table {
    margin: $sp-16 0;
    font-size: 14px;
    font-weight: 600;
    color: $color-b-secondary;
  }

  &.dialog {
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    line-height: 32px;
    color: $color-b-primary;
  }

  &.furniture-heading-hug-top {
    margin-top: 0;
  }
}

.thin {
  font-weight: 300;
}
.thick {
  font-weight: 700;
}
.normal {
  font-weight: 400;
}

.truncated {
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
