.v3-group-results-filtering {
  width: 100%;
  margin-bottom: $sp-24;

  .response-rate-values-container {
    display: flex;
    align-items: center;
  }

  .response-rate-value {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    min-width: 20vw;
    margin-top: $sp-16;
    height: 320px;

    @include ie11-and-narrow(flex, none);
  }

  .trendline-button {
    margin-left: $sp-8;
    font-size: $sp-12;
  }

  .v3-group-results-filtering-card {
    flex: 1 1 auto;
  }

  .label-container {
    display: grid;
    grid-template-columns: 19% 19% 18% 19% 25%;
    padding: $sp-16;

    .category-labels {
      color: $color-tide-170;
      font-size: $sp-16;
      font-weight: 500;
      padding-right: $sp-2;
      margin: 0 $sp-12;
      width: max-content;
    }

    .category-values {
      color: $color-tide-200;
      font-size: $sp-16;
      font-weight: $font-semibold;
      margin-top: 0;
      margin: 0 $sp-12;
      width: 100%;
    }

    .text-button {
      cursor: pointer;

      color: $color-motion-200;

      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
    }

    .label-icon-container {
      display: flex;
      align-items: flex-start;
    }

    .popover-text {
      color: #fff;
      font-size: 12px;
      padding: $sp-8 $sp-12 $sp-20 $sp-12;
      background-color: $color-tide-200;
      border-radius: 6px;
      height: 24px;
    }
  }

  .values-download-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px $color-tide-120 solid;
    flex-wrap: wrap;
    overflow-y: auto;

    .download-container {
      margin: $sp-16;

      .downloadButton {
        width: 34px;
        height: 36px;
      }
    }
  }

  .label-container-wrapper {
    max-width: 100%;
    min-width: 80%;
  }

  .participation-percentage {
    white-space: nowrap;
  }

  @media screen and (max-width: $bp-2xl) {
    .label-container {
      display: block;
    }

    .label-container-wrapper {
      display: flex;
    }
  }

  @media screen and (min-width: $bp-lg) {
    .label-container {
      display: grid;
    }

    .label-container-wrapper {
      display: block;
    }
  }
}
