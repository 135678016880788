.category-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid $color-light-shadow;
  position: relative;
  align-items: center;

  .category-title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    z-index: 4;
  }
}
