.checkbox-and-label {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
}

.furniture-checkbox-container {
  .check-icon {
    color: $color-w-primary;
    width: 14px;
    height: 14px;
  }

  .check-icon-disabled {
    color: $color-b-secondary;
    border: 1px solid $color-b-12;
  }
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + .furniture-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background: $color-w-primary;
    border: 1px solid $color-gray-80;
    border-radius: 2px;
    margin-right: 10px;
    cursor: pointer;

    &.disabled {
      background: $color-b-12;
    }
  }

  input[type='checkbox']:checked + .furniture-checkbox {
    background: $color-action-secondary-500;

    &.disabled {
      background: $color-b-12;
    }
  }
}
