.results-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  width: 100%;
  line-height: normal !important;

  .filter-chips-row-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-faint-gray;
    border-top: 1px solid $color-faint-gray;
    min-height: 51px;

    .filter-chips {
      flex: 1;
      display: flex;
      padding: $sp-8;
      overflow-x: auto;
      align-items: center;
      color: $color-faint-gray;
      user-select: none;

      .filter-chip {
        height: 34px;
        background-color: $color-bg;
      }
    }

    .filter-chips-label {
      font-size: 14px;
      font-weight: 600;
      color: $color-b-secondary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: $sp-24;
      min-width: 175px;
    }

    .filter-expand-collapse-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 $sp-24;
      font-size: 14px;
      font-weight: 700;

      a {
        cursor: pointer;
      }

      .filter-expand-collapse-button {
        position: relative;
        user-select: none;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      }

      .disabled {
        cursor: help;
        color: $color-b-disabled;
      }

      // Custom dashed underline
      .disabled:after {
        content: ' ';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 14px;
        border: 1px dashed $color-b-disabled;
      }
    }

    .filter-results-submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: $color-blue;
      background-color: $color-blue-hover;
      min-width: 135px;
      border-left: 1px solid $color-faint-gray;
      border-top-right-radius: 5px;
      cursor: pointer;
      overflow: hidden;
      user-select: none;
    }
  }

  .filter-picker-container {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    padding: 0 $sp-20;

    .picker-label {
      margin-left: 3px;
      font-weight: 600;
    }

    .check-button-picker-container {
      flex: 1 0 25%;
      display: flex;
      flex-direction: column;
      padding: 10px $sp-4;

      .check-button-picker-buttons-wrapper {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        margin: 10px 0;

        .check-button-picker-button {
          flex: 1;
          margin: 3px;
        }
      }

      .show-more-toggle {
        display: flex;
        justify-content: flex-end;
        margin: 0 3px;

        a {
          cursor: pointer;
        }
      }
    }

    .dropdown-picker-container {
      flex: 1 0 25%;
      padding: 10px $sp-4;

      .dropdown-picker-menu-container {
        margin: 13px 0 10px 3px;
      }
    }

    .date-range-picker-container {
      flex: 1 0 25%;
      padding: 5px;

      .date-range-picker-items-row {
        display: flex;
        margin: 13px 0 10px 3px;

        .date-range-picker-text-field {
          width: 50px;
        }

        .date-range-picker-dropdown-wrapper {
          flex: 1 1 auto;
          padding-left: 6px;
        }

        .separator-label {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          padding: 0 7px;
        }
      }
    }
  }
}

.results-filter-button-bar {
  display: flex;
  flex-flow: row wrap;
  margin: $sp-8;
  align-items: center;
}

.search-results-container {
  height: 100%;
}
