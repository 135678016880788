.smart-tables {
  .outside {
    background-color: transparent;
    padding: $sp-24;
  }

  .heading {
    background-color: #f5f5f5;
    border-bottom: 1px solid #d8d8d8;
    padding: $sp-8 $sp-16;
    display: flex;
    align-items: center;
  }
}

.smart-table-reorder-cell {
  position: relative;
  top: -7px;
}

.empty-table-container {
  background-color: $color-gray-background;
  margin: $sp-8 $sp-16 $sp-16 $sp-16;
  padding: $sp-8 $sp-24;

  .empty-table-content {
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    color: $color-b-secondary;
    box-sizing: border-box;
  }
}

td,
th {
  padding: 5px 15px;
  overflow-wrap: break-word;
  text-align: left;
}

th {
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
}

tr {
  border-bottom: 1px solid $color-faint-gray;
}

tr:last-child {
  border-bottom: none;
}
.table-header {
  height: auto;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.table-row {
  height: 48px;
}
.table-footer {
  height: auto;
  background-color: #f5f5f5;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

.table-container {
  overflow-x: scroll;
}

.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $color-gray-80;
  box-shadow: 0 0 1px $color-faint-gray;
}

.footer-pagination-select-field {
  min-width: 70px !important;
  margin-left: $sp-8;
}

.footer-pagination {
  display: flex;
  background-color: $color-w-primary;
  flex-wrap: wrap;
  padding: $sp-16;

  .footer-rows-per-page {
    display: flex;
    align-items: center;
    flex: 0 0 25%;
    max-width: 25%;
    white-space: nowrap;
    padding: $sp-8 0;
  }

  .furniture-pagination-container {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: center;
    list-style: none;
    border-radius: 0.25rem;
    margin: $sp-4 0;
    padding: 0;
    align-items: center;

    &.no-limit-select {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @media screen and (max-width: $bp-xl) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .furniture-pagination-item {
    border: none;
    cursor: pointer;

    &:first-child .furniture-pagination-link {
      margin-left: 0;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child .furniture-pagination-link {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .furniture-pagination-link {
      position: relative;
      display: block;
      padding: 0.4375rem 0.75rem;
      margin-left: -1px;
      line-height: 1.5;
      border: 1px solid $color-tide-170;
      cursor: pointer;
      color: $color-tide-200;
      background-color: $color-w-primary;

      &:focus {
        box-shadow: 0 0 0 1px $color-w-primary, 0 0 0 2px $color-motion-200;
        z-index: 3;
      }
    }

    &.furniture-pagination-active {
      .furniture-pagination-link {
        color: $color-w-primary;
        background-color: $color-motion-200;
      }
    }

    &.disabled {
      .furniture-pagination-link {
        color: $color-tide-170;
        background-color: $color-tide-110;
        border-color: $color-tide-110;
      }
    }
  }

  .furniture-footer-count {
    display: flex;
    align-items: center;

    @media screen and (max-width: $bp-xl) {
      width: 100%;
      justify-content: center;
    }
  }
}

.popover-table {
  td {
    padding: $sp-20;
  }

  th {
    padding: $sp-20;
    text-align: left;
    font-weight: lighter;
  }
}
