.ast-title {
  @include ultra-narrow(padding, 0 $sp-8);

  > h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;

    .details {
      font-weight: normal;
      font-size: 16px;
    }

    .toggle-details {
      padding-left: $sp-8;
      font-weight: normal;
      font-size: 16px;
    }
  }

  > p {
    font-size: 16px;
    font-style: italic;
  }
}

.ast-results-section-card {
  margin-top: $sp-32;
}

.ast-results-section-title {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  padding: 0 0 0 $sp-24;
  @include ultra-narrow(padding, 0 $sp-8);

  .title {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    h3 {
      flex: 1 0 auto;
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      padding-top: $sp-24;
      // When wrapping the key underneath, we do not want padding between the title and the key
      // So moved the bottom padding to the flex rather than the item
    }

    .legend {
      // Roughly detect when we have to wrap the legend
      @include ultra-narrow(padding-top, $sp-16);
      margin-right: 0;
    }
  }

  > p {
    margin: 0;
    padding-bottom: $sp-24;
    font-size: 16px;
    line-height: 20px;
    @include ultra-narrow(padding-bottom, 0);
  }
}

.ast-tbl {
  position: relative;
}

.ast-tbl-hdr {
  background-color: #f0f0f0;
  font-size: 14px;
  padding: $sp-8 $sp-24;
  // For the initial hacky version, no header on mobile
  @include ultra-narrow(display, none !important);

  > .ast-tbl-question {
    flex: 1 0 auto;
  }

  > .ast-tbl-hdr-ldr {
    display: flex;
    justify-content: flex-end;
    padding-right: 124px;
  }

  > .ast-tbl-disagree,
  > .ast-tbl-neither,
  > .ast-tbl-agree {
    flex: 0 0 140px;
  }

  > .ast-tbl-disagree {
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 416px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }
  > .ast-tbl-neither {
    text-align: center;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 235px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }
  > .ast-tbl-agree {
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 45px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }

  > .ast-tbl-hdr-0,
  > .ast-tbl-hdr-25,
  > .ast-tbl-hdr-50,
  > .ast-tbl-hdr-75,
  > .ast-tbl-hdr-100 {
    flex: 0 0 84px;
    text-align: center;
  }

  > .ast-tbl-hdr-0 {
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 443px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .ast-tbl-hdr-25 {
    padding-left: 10px;
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 335px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .ast-tbl-hdr-50 {
    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 233px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .ast-tbl-hdr-75 {
    padding-right: 10px;
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 135px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .ast-tbl-hdr-100 {
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 24px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }
}

.ast-tbl-body {
  .ast-tbl-row {
    padding: $sp-24;
    @include ultra-narrow(padding, $sp-24 0);
  }

  .ast-tbl-row:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.ast-tbl-row {
  // We want the bar below the row on mobile
  @include ultra-narrow(flex-wrap, wrap);
  .ast-tbl-row-question {
    flex: 1 1 auto;
    line-height: 22px;
    font-size: 16px;
    padding: 6px $sp-24 6px 0;
    @include ultra-narrow(padding, 6px $sp-8);
  }

  .ast-tbl-row-result {
    flex: 0 0 420px;
    // Bar takes full width on mobile
    @include ultra-narrow(flex, 0 0 100%);
    @include ultra-narrow(padding, 0 $sp-8);
  }

  .ast-tbl-row-ldr {
    font-size: 14px;
    width: 84px;
    display: flex;
    margin-right: $sp-64;
    justify-content: center;
    align-items: center;

    > .strongly_disagree,
    > .disagree,
    > .neither,
    > .agree,
    > .strongly_agree {
      width: 84px;
      border: 1px solid white;
      padding: 9px $sp-12;
      text-align: center;
      border-radius: 4px;
      max-height: 34px;
    }

    .strongly_disagree {
      background-color: $color-worst;
    }

    .disagree {
      background-color: $color-bad;
    }

    .neither {
      background-color: $color-neutral;
    }

    .agree {
      background-color: $color-good;
    }

    .strongly_agree {
      background-color: $color-best;
    }
  }
}

.ast-likert-breakdown {
  font-size: 14px;
  align-items: center;
  z-index: 100;
  position: relative;
  border-radius: 4px;

  > .strongly_disagree,
  > .disagree,
  > .neither,
  > .agree,
  > .strongly_agree {
    border: 1px solid white;
    padding: 9px $sp-12;
    text-align: center;
    align-items: center;
  }

  > div:first-child {
    border-radius: 4px 0 0 4px;
  }

  > div:last-child {
    border-radius: 0 4px 4px 0;
  }

  .strongly_disagree {
    background-color: $color-worst;
  }

  .disagree {
    background-color: $color-bad;
  }

  .neither {
    background-color: $color-neutral;
  }

  .agree {
    background-color: $color-good;
  }

  .strongly_agree {
    background-color: $color-best;
  }
}

// update when engage-rebrand in live.
.rebrand-app {
  .ast-likert-breakdown {
    font-size: 14px;
    align-items: center;
    z-index: 100;
    position: relative;
    border-radius: 4px;

    > .strongly_disagree,
    > .disagree,
    > .neither,
    > .agree,
    > .strongly_agree {
      border: 1px solid white;
      padding: 9px $sp-12;
      text-align: center;
      align-items: center;
    }

    > div:first-child {
      border-radius: 4px 0 0 4px;
    }

    > div:last-child {
      border-radius: 0 4px 4px 0;
    }

    .strongly_disagree {
      background-color: $color-worst;
    }

    .disagree {
      background-color: $color-bad;
    }

    .neither {
      background-color: $color-neutral;
    }

    .agree {
      background-color: $color-good;
    }

    .strongly_agree {
      background-color: $color-best;
    }
  }

  .legend {
    display: flex;
    flex-direction: row;
    justify-content: around;
    font-size: 13px;
    @include ultra-narrow(margin, 0);

    .key {
      font-weight: 600;
    }

    > .strongly_disagree,
    > .disagree,
    > .neither,
    > .agree,
    > .strongly_agree {
      display: inline-block;
      padding-right: $sp-20;
    }

    .circle {
      border-radius: 50%;
      width: 13px;
      height: 13px;
      @include ultra-narrow(width, 12px);
      @include ultra-narrow(height, 12px);
      display: inline-block;
      margin-right: $sp-16;
      margin-left: $sp-16;

      @include ultra-narrow(margin-right, $sp-8);
      @include ultra-narrow(Margin-left, $sp-8);

      &.strongly_disagree {
        background-color: $color-worst;
      }

      &.disagree {
        background-color: $color-bad;
      }

      &.neutral {
        background-color: $color-neutral;
      }

      &.agree {
        background-color: $color-good;
      }

      &.strongly_agree {
        background-color: $color-best;
      }
    }
  }
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: around;
  font-size: 13px;
  @include ultra-narrow(margin, 0);

  .key {
    font-weight: 600;
  }

  > .disagree,
  > .neutral,
  > .agree {
    display: inline-block;
    padding-right: $sp-20;
  }

  .circle {
    border-radius: 50%;
    width: 13px;
    height: 13px;
    @include ultra-narrow(width, 12px);
    @include ultra-narrow(height, 12px);
    display: inline-block;
    margin-right: $sp-16;
    margin-left: $sp-16;

    @include ultra-narrow(margin-right, $sp-8);
    @include ultra-narrow(Margin-left, $sp-8);

    &.strongly_disagree {
      background-color: $color-worst;
    }

    &.disagree {
      background-color: $color-bad;
    }

    &.neutral {
      background-color: $color-neutral;
    }

    &.agree {
      background-color: $color-good;
    }

    &.strongly_agree {
      background-color: $color-best;
    }
  }
}

.response-rate-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $sp-40 $sp-24;
  font-size: 18px;
  line-height: 22px;
}
