.empty-state-footer {
  padding: $sp-32 0;
  background-color: $color-w-primary !important;

  .empty-state-text {
    font-size: 16px;
  }

  div {
    border: none !important;
  }

  td {
    height: 0px !important;
    width: 0px !important;
    padding: 0 !important;
  }
}

.table-header-icon-wrapper {
  width: 10px;
}

.redux-cell-edit-td {
  padding: 0;
  margin-right: $sp-24;
}
