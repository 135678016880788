.add-group-results-access-error-text {
  display: inline-block;
  padding-left: $sp-8;

  &.text-and-icon-container {
    position: relative;
    width: 485px;
    height: 36px;

    .text-and-icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 12px;
      width: 485px;
      height: 36px;
    }
  }
}
