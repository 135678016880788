.furniture-expansion-panel {
  position: relative;
  margin: 16px 0 !important;
  border-radius: 0 !important;

  & > div {
    transition: height 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  &:not(.expanded) {
    border-radius: 0 !important;
  }

  &.expanded {
    border-radius: 2px !important;
  }
}

.expansion-panel-headline {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: $sp-16 0 $sp-16 $sp-16;
  }

  &.with-content {
    h4 {
      margin: $sp-16 0 0 $sp-16;
    }
  }

  &.without-children {
    cursor: default;
    pointer-events: none;

    .expansion-panel-toggle {
      display: none;
      pointer-events: none;
    }
  }
  &.disabled {
    cursor: default;
  }
}

.expansion-panel-summary {
  position: relative;

  > :first-child {
    width: calc(100% - 48px - 16px);
  }

  .expansion-panel-toggle {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
  }
}

.expansion-panel-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;

  &.has-action {
    bottom: 53px;
  }
}

.expansion-panel-actions {
  padding: $sp-8 $sp-16 !important;
  display: flex;

  &.divider {
    border-top: $light-grey-border;
  }

  button {
    cursor: pointer;
  }
}
