.employee-management-table {
  display: flex;
  flex: 1;
  padding: 0;

  .header-row {
    padding: $sp-12 18px;
    border-bottom: 1px solid $color-table-border;

    .header-field {
      flex: 1;
      margin: 0;
      font-size: 13px;
      color: $color-stack-gray;

      &.row-number-header {
        max-width: 10.5%;
      }

      &.ff-header {
        max-width: 30%;
      }

      .header-span {
        margin: 0;
      }
    }
  }

  .row {
    min-height: 40px;
    padding: 0 18px;
    border-bottom: 1px solid $color-table-border;
  }

  .selected-row {
    background-color: $color-hover-gray;
  }

  .removed-row {
    color: $color-disabled;
    background-color: $color-hover-gray;
  }

  .row-field {
    flex: 1;
    padding-right: $sp-12;
    margin: 0;
  }

  .row-number {
    min-width: 10%;
  }

  .ff-row {
    min-width: 30%;
    max-width: 30%;
  }

  .error-message {
    flex: 5 0 50%;
  }

  .error-message-for-ff {
    flex: 4 0 45%;
  }

  .movement-arrow {
    color: $color-stack-gray;
    padding: 0 3px;
  }
}

.deactivation-picker {
  margin: $sp-4 0;
}

.employee-movement-arrow {
  color: $color-b-primary;
  height: 24px;
  width: 24px;
}

.inactive-count {
  color: $color-stack-gray;
}

.red-arrow {
  color: red !important;
  height: 16px !important;
  width: 16px !important;
}

.orange-arrow {
  color: orange !important;
  height: 16px !important;
  width: 16px !important;
}

.green-arrow {
  color: green !important;
  height: 16px !important;
  width: 16px !important;
}

.red-bar {
  border-left: 3px solid $color-ruby-tint-7;
}

.stepper-step {
  height: 30px;
  width: 30px;
  background-color: $color-light-cerulean;
  color: $color-deep-cerulean;
  border-radius: 50%;
  top: 5%;
  font-weight: bold;

  &.active-color {
    background-color: $color-grow-dark-blue;
    color: white;
  }

  &.completed-color {
    background-color: $color-hover-gray;
    color: $color-hover-gray;
  }
}

.stepper-card {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
}

.stepper-card-wrapper {
  flex: 2 1 20%;
  margin: 2px;
  min-width: 150px;
  min-height: 160px;

  &.active-color {
    border-radius: 3px;
    border: 3px solid $color-grow-dark-blue;
  }
}

.stepper-cards-wrapper {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
}

.completed-color {
  color: $color-grey-2;
}

.group-select-field {
  padding: $sp-8;
  height: 30px;
  min-width: 120px;
  max-width: 400px;
}

.header-error-card {
  border-left: 3px solid $color-ruby-tint-7;
}
