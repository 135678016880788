.profile-primary-group-modal {
  .profile-primary-group-body-content {
    padding: $sp-24;

    .primary-group-radio {
      padding: $sp-8;
    }
  }

  .button-bar {
    display: flex;
    justify-content: space-between;
  }

  .button-bar-right-only {
    display: flex;
    justify-content: flex-end;
  }

  .flat-button {
    color: $color-blue;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flat-button.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}

.profile-survey-info-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .relationship-name-label {
    color: $color-b-secondary;
  }

  .group-name-label {
    padding-left: $sp-8;
    padding-right: $sp-8;
    color: $color-b-secondary;
  }

  .label-name-label {
    color: $color-b-secondary;
  }

  .row-label {
    font-style: italic;
    color: $color-stack-gray;
  }
}

.edit-profile-label {
  color: $color-blue;
  text-transform: uppercase;
}

.label-field {
  min-width: 150px;
  padding: $sp-24;
  margin: $sp-8;
  border: 1px solid $color-b-primary;
  font-weight: normal;
  text-align: center;
  color: $color-b-primary;
  cursor: pointer;
}

.label-field-selected {
  min-width: 150px;
  padding: $sp-24;
  margin: 6px;
  border: 3px solid $color-blue;
  font-weight: bold;
  text-align: center;
  color: $color-blue;
  cursor: pointer;
}

.other-label-text {
  margin-top: $sp-16;
  font-size: 22px;
  font-style: bold;
}

.label-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.edit-profile-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
