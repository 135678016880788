.trendline-axis {
  display: flex;
  flex: 1 0 0%;
  height: 36px;
  min-height: 36px;
  align-items: center;
  justify-content: space-between;
  margin: 0 $sp-20 0 $sp-36;
  padding-right: $sp-8;

  span {
    white-space: unset !important;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: hsl(0, 0%, 50%);
  }

  /* This overrides span styling because of css order */
  .skeleton {
    color: transparent;
  }
}

.category-score-trendline {
  fill: none;
  stroke: #093579;
  stroke-width: 4px;
}

.category-score-trendline-missing {
  fill: none;
  stroke: $color-dark-gray;
  stroke-width: 2px;
  stroke-dasharray: 5;
}

.category-score-trendline.hover {
  stroke: rgba(0, 0, 0, 0.65);
  z-index: 100;
}

.category-score-datapoint-hover {
  fill: rgba(0, 0, 0, 0.65);
  z-index: 100;
}

.trend-score-trendline-1 {
  stroke: #b5b5b5;
}

.trend-score-trendline-2 {
  stroke: #b5b5b5;
}

.category-score-datapoint {
  fill: #0b3e8b;
  font-size: 36px;
}

.category-score-datapoint.hover {
  fill: rgba(0, 0, 0, 0.65);
  z-index: 100;
}

.trend-score-datapoint-1 {
  fill: #b5b5b5;
}

.trend-score-datapoint-2 {
  fill: #b5b5b5;
}

.summarize-category-trendline-yaxis {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.category-score-baseline {
  stroke: $color-more-mid-gray;
  stroke-width: 1px;
}
