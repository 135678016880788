.landscape-only-chart-container {
  padding: $sp-24;
  text-align: center;
}

#rotate-device-icon {
  width: 50%;
  height: 50%;
  margin-bottom: 15px;
}
