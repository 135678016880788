.filter-button-tooltip {
  line-height: 24px;
}

.filtering-container{
  display: flex;
  padding: $sp-8;
}

.parent-group-chip{
  width: 16px;
  padding: $sp-8;
}
