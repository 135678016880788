.customer-csv-card-header {
  display: flex;
  justify-content: flex-end;
}

.customer-csv-upload-button-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  .file-attach-input {
    position: absolute;
    top: 10;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2000;
  }
}

.customer-csv-table {
  display: flex;
  padding: 0;

  .header-row {
    padding: 18px;
    border-bottom: 1px solid $color-table-border;

    .header-field {
      flex: 1;
      margin: 0;
      font-size: 13px;
      color: $color-stack-gray;

      .header-span {
        margin: 0;
      }
    }
  }

  .row {
    padding: 18px;
    border-bottom: 1px solid $color-table-border;
  }

  .row-field {
    flex: 1;
    margin: 0;
  }
}

.job-id-button {
  padding-left: 0;
}

.highlighted-label {
  font-weight: bold;
}
