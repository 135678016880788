.employee-edit {
  > div {
    position: relative;
  }

  .employee-edit-content {
    padding: 0 $sp-16 $sp-16 $sp-16;
  }

  .employee-edit-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;

      &.multiline {
        flex-direction: column;
      }
    }
  }

  h5 {
    color: $color-b-primary;
    font-size: 16px;
    margin: 0;
  }

  .body {
    margin: 0;
    padding-top: $sp-8;
    padding-right: $sp-32;
    font-size: 15px;
    color: $color-b-secondary;
    line-height: 1.618;
  }

  .status {
    position: absolute;
    right: 48px;
    top: 16px;
  }

  .link {
    color: $color-blue;
    cursor: pointer;
  }

  .education-vocab {
    svg {
      height: 16px !important;
    }
  }
}

.employee-edit-table {
  margin-top: $sp-20;
}

.row-navigate-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  z-index: 100;

  svg {
    color: $color-blue !important;
  }
}

.employment-entry {
  display: flex;
  align-items: center;
  margin: 14px 0;
  font-size: 18px;
  line-height: 24px;

  .employment-status {
    margin-left: auto;
  }
}

.new-employee-attribute-submit-button {
  margin-top: 25px;
}
