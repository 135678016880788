.tpz-select-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $sp-16;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  min-width: 150px;
  max-width: 600px;
  border: 1px solid $color-onyx-tint-8;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: default;
    border-color: $color-tide-140;
    background-color: $color-disabled-background;
    color: $color-disabled-text;

    svg {
      color: $color-gray-description !important;
    }
  }

  // arrowdown icon
  svg {
    margin: 0 $sp-4;
    padding: $sp-2;
  }
}
