.furniture-v2-dropdown-menu-popover {
  display: flex;
  flex-direction: column;
  // set max height to roughly 10 items
  max-height: 525px !important;
  overflow-y: auto !important;
}

.dropdown-menu-popover-root {
  width: fit-content !important;
}

.furniture-v2-dropdown-menu-item {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: $sp-8;
  margin: 2px 0;
  border-radius: 5px; // match the searchfield
  border-top: none !important; // override existing style above
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  outline: none;
  &.disabled {
    color: $color-b-disabled !important;
    background-color: $color-w-disabled !important;
    pointer-events: none;
  }

  &.disabled-with-tooltip {
    color: $color-b-disabled !important;
    background-color: $color-w-disabled !important;
  }

  &.selected {
    color: $color-tide-200;
    background-color: transparent;
    font-weight: 600;

    svg {
      color: $color-motion-200;
      margin-right: $sp-4;
    }
  }

  .secondary-text-field {
    flex: 1 1 auto;
    max-width: 400px;
    flex-flow: row wrap;
    white-space: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    padding-top: $sp-4;
  }
}

.furniture-v2-dropdown-menu-item:hover,
.furniture-v2-dropdown-menu-item:focus {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  color: $color-tide-200;
  background-color: $color-motion-100;
  border: none;
  border-radius: 0;
}

.furniture-v2-dropdown-search-menu-items-container {
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-check-menu-items-container {
  max-height: 275px;
  overflow-y: auto;
  user-select: none;

  .dropdown-check-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid $color-gray-80;
    cursor: pointer;

    .dropdown-check-menu-item-label {
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .dropdown-check-menu-item-disabled {
    color: $color-b-disabled;
    background-color: $color-w-disabled;
    pointer-events: none;
  }

  .dropdown-check-menu-item:hover {
    color: $color-blue;
    background-color: $color-blue-hover;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}

.dropdown-check-menu-search-text-container {
  border-top: 1px solid $color-gray-80;
}

.furniture-more-dropdown {
  border: 1px solid $color-tide-200;
  min-width: 40px;
  width: 40px;
  height: 34px;
}
