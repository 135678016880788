.d3-summary-influence {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d3-summary-influence-svg {
  float: left;
  overflow: visible;
}

.d3-summary-influence-outer-circle {
  stroke: hsl(0, 0%, 82%);
  stroke-width: 2;
  fill: transparent;
  stroke-dasharray: 5 3;
}
