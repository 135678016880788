.campaign-statuses-active-dialog-content {
  color: $color-b-primary;
  font-size: 14px;

  .campaign-statuses-active-dialog-content-description {
    font-size: 18px;
    line-height: 22px;
  }

  .dialog-icon-row-container {
    border: 0.5px solid $color-more-mid-gray;
    border-radius: 5px;
  }
}
