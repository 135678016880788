.furniture-dialog {
  position: absolute;
  max-width: 75%;
  margin: 0px auto;
  z-index: 1500;
  border: none;
  background-color: $color-w-primary;
  border-radius: 8px;
  padding: $sp-24;
  max-height: 85%;
  overflow: auto;
  .dialog-dismiss-button-container {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .furniture-dialog-title {
    padding-bottom: $sp-16;
    font-size: 22px;
    line-height: 32px;
  }

  .furniture-dialog-actions {
    margin-top: $sp-16;
    display: flex;
    float: right;
    flex-wrap: wrap;
  }
}

.furniture-dialog:focus-visible {
  border: none;
  outline: none;
}

.furniture-dialog-overlay {
  display: flex;
  align-items: center;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $color-dark-shadow;
  justify-content: center;
}
