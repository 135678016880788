@keyframes progress {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: right -200px top 0px;
  }
}

.skeleton {
  animation: progress 2.2s ease-in-out infinite;
  color: rgba(0, 0, 0, 0);
  background-color: #dfdbdb;
  background-image: linear-gradient(90deg, #dfdbdb, #f2ecec, #dfdbdb);
  background-repeat: no-repeat;
  border-radius: 2px;
  pointer-events: none;
}

.skeleton-anchor {
  overflow: hidden;
  text-overflow: ellipsis;
}

#cto {
  position: fixed;
  left: 156px;
  width: 100px;
  height: 200px;
  z-index: 12345;
  visibility: hidden;
  background: url('../static/our_cto_peek.png');
  background-size: 100px 200px;
  background-repeat: no-repeat;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  filter: drop-shadow(1px 1px 2px hsla(0, 0%, 0%, 0.35));

  &.visible {
    left: 256px;
    visibility: visible;
  }
}

.skeleton-chip {
  border-radius: 8px;
  margin: 0 $sp-8 $sp-8 0;
  font-weight: 700;
  min-height: 48px;
}
