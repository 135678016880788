.info-bar-live-campaign-dialog {
  display: flex;
  flex-flow: column;

  .live-campaign-stat-label {
    color: $color-b-primary;
  }

  .live-campaign-info-line {
    margin: 6px 0;
  }
}

.info-bar {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  min-height: 44px;
  overflow: hidden;
  border-radius: 8px;
  margin: $sp-16 96px $sp-8 $sp-48;

  &.hide {
    height: 0;
    min-height: 0;
    margin-bottom: 0;
    width: 0;
  }

  .bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
  }

  .info-bar-btn-link {
    color: $color-b-primary !important;
  }

  > span {
    font-size: 16px;
  }

  &.info-info-bar {
    background-color: $color-emplify-blue-2 !important;

    > .bar-icon {
      background-color: $color-emplify-blue-2 !important;
    }
  }

  &.success-info-bar {
    background-color: $color-success-emerald !important;

    > .bar-icon {
      background-color: $color-success-emerald !important;
    }
  }

  &.warning-info-bar {
    background-color: $color-methodology-amber !important;

    > .bar-icon {
      background-color: $color-methodology-amber !important;
    }
  }

  &.error-info-bar {
    background-color: $color-destructive-ruby !important;

    > .bar-icon {
      background-color: $color-destructive-ruby !important;

      > svg {
        color: #fff !important;
      }
    }
  }

  &.ff-info-bar {
    margin: 0 0 $sp-24 0;
    padding: $sp-8;
    background-color: $color-disabled-gray !important;

    > .bar-icon {
      background-color: $color-disabled-gray !important;

      > svg {
        color: $color-b-primary !important;
      }
    }
  }
}

.tpz-info-bar {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  font-size: 16px;
  height: 64px;
  padding: $sp-16;
  overflow: hidden;

  .bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
  }

  .info-bar-btn-link {
    color: $color-b-primary !important;
  }

  > span {
    font-size: 16px;
  }

  &.needs_action {
    background-color: $color-amber-50 !important;
    border: 1px solid $color-ruby-600 !important;
    border-radius: 4px;

    > .bar-icon {
      background-color: $color-amber-50 !important;
    }
  }

  &.warning {
    background-color: $color-amber-50 !important;
    border: 1px solid $color-amber-400 !important;
    border-radius: 4px;

    > .bar-icon {
      background-color: $color-amber-50 !important;
    }
  }

  &.success {
    background-color: $color-amber-50 !important;
    border: 1px solid $color-success-emerald !important;
    border-radius: 4px;

    > .bar-icon {
      background-color: $color-amber-50 !important;
    }
  }
}

.campaign-missing-fields {
  height: fit-content !important;
  background-color: #fff !important;
  align-items: flex-start;

  .bar-icon {
    padding-top: $sp-8;
  }
}

.invalid-statement {
  font-size: 18px;
  color: #1a0d3f;
  font-weight: 600;
}

.error-list {
  line-height: 20px;
}

.error-text {
  font-size: $sp-16;
  color: $color-tide-170;
}
