.hidden-groups-skinny-swatch {
  margin-right: $sp-4;
  max-height: 12px !important;
  min-height: 12px !important;
  min-width: 28px !important;
}

.hidden-groups-toggle {
  display: flex;
  align-items: center;
}

.hidden-group-swwitch-description {
  font-style: 'italic';
  font-weight: 600;
  font-size: 12px;
  color: hsla(0, 0%, 0%, 0.75);
  margin: 3px 28px 0 0;
}

.hidden-group-switch {
  width: 180px;
}
