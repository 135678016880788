.surveys-section-header {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.campaign-section-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.campaign-section-subheader {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: $sp-4;
}

.campaign-notification-section {
  align-items: baseline;
  margin-top: $sp-16;
}

.campaign-section-header-icon {
  margin-right: $sp-8;
}

.campaign-notifications-dropdown {
  height: 34px;
  width: 100%;
  margin-bottom: $sp-8;
}

.campaign-notifications-more {
  float: right;
  padding-right: $sp-16;
}

.campaign-notifications-table {
  margin: 0 $sp-16;
}

.add-notification-dialog {
  height: 400px;
  width: 550px;
}

.add-notification-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: $sp-24;
}
