.helpful-yes-no {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;

  .yn-question {
    margin-right: $sp-8;
    font-size: 16px;
    color: $color-b-primary;
  }

  .yn-button {
    color: $color-blue;
    cursor: pointer;
  }
}
