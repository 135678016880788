$color-b-primary: hsla(0, 0%, 0%, 0.87);
$color-b-secondary: hsla(0, 0%, 0%, 0.54);
$color-b-disabled: hsla(0, 0%, 0%, 0.38);

$color-w-primary: hsla(0, 0%, 100%, 1);
$color-w-secondary: hsla(0, 0%, 100%, 0.7);
$color-w-disabled: hsla(0, 0%, 100%, 0.5);

$color-b-12: hsla(0, 0%, 0%, 0.12);
$color-b-60: hsla(0, 0%, 0%, 0.6);
$color-b-75: hsla(0, 0%, 0%, 0.75);
$color-info-bg: hsla(0, 0%, 0%, 0.04);
$color-bg: hsl(0, 0%, 94%);

$color-blue: #3facdb;
$color-dark-blue: #18669e; // careful! this var is being overwritten below!
$color-emplify-blue-2: #5ca9dc;
$color-darkest-blue: #12222e; // Does not match src/constants/colors
$color-blue-hover: #e8f6fc;
$color-grow-blue: #dcedf8;
$color-grow-dark-blue: #007ba2;
$color-grow-black-border: #979797;
$color-onyx-tint-3: #6f6f6f;
$color-gray-background: #f8f8f9;
$color-info-blue: #eff6fc;
$color-black: #000000;
$color-white: #ffffff;

$color-worst: #d13941;
$color-bad: #ff6f41;
$color-neutral: #f6be55;
$color-good: #16dbdb;
$color-best: #12afaf;
$color-spark: #8226e0;

$color-green-sea-green: #348f65;
$color-red-fruit-punch: #d64820;

/*
 * These were all introduced for "manager home page"
 * Not sure if they are going to stick around
 * If they become part of a standard pallet we should rename them to something more clear
*/
$color-bad-2: #931a29;
$color-moderate-2: #fbb316;
$color-good-2: #4e8d68;
$color-grey-2: #dcdcdc;
$color-toupe-2: #f5f6f1;

$color-lowest: #ed4609;
$color-low: #ff8c00;
$color-normal: #e6ddca;
$color-high: #3b99b3;
$color-highest: #18669e;

$color-redelete: #eb4720;
$color-green-infobar: #558b2f;
$color-info-infobar: #039be5;
$color-success-infobar: #82c51b;
$color-warning-infobar: #ffb50a;
$color-disabled: #aaaaaa;

$light-grey-border: 1px solid $color-b-12;

$green-500: #4caf50;
$blue-400: #42a5f5;
$deep-purple-200: #ce93d8;

$color-light-gray: #e0e0e0;
$color-faint-gray: #e0e0e1;
$color-stack-gray: hsl(0, 0%, 55%);
$color-gray-80: hsl(0, 0%, 80%);
$color-mid-gray: #e5e5e5;
$color-more-mid-gray: #e6e6e6;
$color-dark-gray: #323232;
$color-hover-gray: #f8f8f8;
$color-tab-hover-gray: #eaeaea;
$color-search-back-gray: #f5f5f5;
$color-search-icon-gray: rgba(0, 0, 0, 0.54);
$color-gray-700: #4d4d4d;

$color-score-increase: #558b2e;
$color-score-decrease: $color-redelete;

$color-drawer-border: #ededed;
$color-table-border: #e4e4e4;

$color-drop-shadow: rgba(26, 13, 63, 0.04);
$color-light-shadow: rgba(0, 0, 0, 0.12);
$color-dark-shadow: rgba(0, 0, 0, 0.56);

$color-gray-description: #838383;

$color-dark-blue: #2d536c;
$color-disabled: #b2b2b2;

$color-assessment-promoter: #57d195;
$color-assessment-passive: #e6e8ea;
$color-assessment-detractor: #eb8989;

//15five colors
$color-action-secondary-500: #46008f;
$color-basic-blue: #007bff;
$color-motion-90: #f8f4fd;
$color-motion-100: #f0e9fb;
$color-motion-200: #6c00db;

$color-rise-50: #ffede7;
$color-rise-100: #ffd2c4;
$color-rise-200: #db3700;
$color-rise-300: #ad3b1c;

$color-tide-110: #e7e7eb;
$color-tide-120: #d1cfd9;
$color-tide-140: #a49fb2;
$color-tide-160: #746d8a;
$color-tide-170: #5e5777;
$color-tide-180: #483e64;
$color-tide-200: #1a0d3f;
$color-kin-200: #fff2e8;
$color-kin-90: #fffcfb;

$color-disabled-background: rgb(242, 242, 242);
$color-disabled-text: rgb(153, 153, 153);

$color-cornbread-160: #f8ce7f;

// rebrand colors
$color-negative-160: #dc6b71;
$color-negative-120: #f3ced0;
$color-neutral-gray: #e6e8ea;
$color-positive-140: #abf1dc;
$color-positive-180: #57e2b9;

$color-ruby-800: #74221f;
$color-ruby-200: #f0cecc;
$color-navy-100: #181226;
$color-navy-200: #302651;
$color-amber-300: #f9e5ac;
$color-emerald-900: #436022;
$color-emerald-200: #e2f0d1;

// Typography
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;

$font-size-sm: 0.875rem;
$font-size-m: 1rem;

// Spacing (margin, padding)
$sp-2: 2px;
$sp-4: 4px;
$sp-8: 8px;
$sp-12: 12px;
$sp-14: 14px;
$sp-16: 16px;
$sp-20: 20px;
$sp-24: 24px;
$sp-28: 28px;
$sp-32: 32px;
$sp-36: 36px;
$sp-40: 40px;
$sp-44: 44px;
$sp-48: 48px;
$sp-52: 52px;
$sp-56: 56px;
$sp-60: 60px;
$sp-64: 64px;
$sp-96: 96px;

// Gap Spacing
$gap-8: $sp-8;
$gap-16: $sp-16;
$gap-24: $sp-24;

// Breakpoints
$bp-unit: 'px';
$bp-num-xs: 480;
$bp-num-sm: 640;
$bp-num-md: 768;
$bp-num-lg: 992;
$bp-num-xl: 1024;
$bp-num-2xl: 1200;
$bp-xs: #{$bp-num-xs}#{$bp-unit};
$bp-sm: #{$bp-num-sm}#{$bp-unit};
$bp-md: #{$bp-num-md}#{$bp-unit};
$bp-lg: #{$bp-num-lg}#{$bp-unit};
$bp-xl: #{$bp-num-xl}#{$bp-unit};
$bp-2xl: #{$bp-num-2xl}#{$bp-unit};

// drawer - but wide enough, no drawer - but wide enough
@mixin wide($prop, $val) {
  @media (min-width: $bp-2xl), (min-width: 900px) and (max-width: 991px) {
    #{$prop}: $val;
  }
}

// drawer - narrow, no drawer - tablet
@mixin narrow($prop, $val) {
  @media (min-width: $bp-lg) and (max-width: 1199px), (max-width: 899px) {
    #{$prop}: $val;
  }
}

// drawer - mobile devices
@mixin ultra-narrow($prop, $val) {
  @media (max-width: $bp-sm) {
    #{$prop}: $val;
  }
}

// drawer - open
@mixin drawer-open($prop, $val) {
  @media (min-width: $bp-lg) {
    #{$prop}: $val;
  }
}
// drawer - closed
@mixin drawer-closed($prop, $val) {
  @media (max-width: 991px) {
    #{$prop}: $val;
  }
}
@mixin ie11-and-narrow($prop, $val) {
  @media all and (-ms-high-contrast: none) and (min-width: $bp-lg) and (max-width: 1199px),
    (max-width: 899px) {
    #{$prop}: $val;
  }
}

@mixin ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin faint-striping {
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 4px,
    #d9d9d9 4px,
    #d9d9d9 8px
  );
  background-color: #ececec;
}
