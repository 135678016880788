.feedback-responses {
  display: flex;
  flex-direction: column;

  .tools-section {
    flex: 1 1 162px;

    // Added this to override the passed in underline from the response search component
    .response-search-wrapper {
      border: inherit;
    }
  }

  &-list {
    display: flex;
    flex: 2 2 400px;
    flex-direction: column;
    @include narrow(padding-right, 0);
  }
}

.page-button {
  min-width: 0 !important;
}

.page-limit-selector {
  min-width: 0 !important;
  margin: 0 $sp-8;
}

.stuck {
  // 48px is the exact size of the header on the tools card
  top: 48px;
  z-index: 1000;
}

.feedback-footer-pagination-button {
  display: flex;
  flex: 0 0 30%;
  justify-content: center;

  @media screen and (max-width: $bp-lg) {
    width: 100%;
    flex: 0 0 100%;
  }
}

.insight-card {
  background: linear-gradient(
    90deg,
    rgba(22, 219, 219, 0.1),
    rgba(130, 38, 224, 0.1)
  );
}
