/* The animation code */
@keyframes expand {
  from {
    width: 0;
  }
}

.expandable {
  padding-top: 6px;
  animation: expand 0.4s ease;
}
