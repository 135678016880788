.furniture-page-subtitle-wrapper {
  display: flex;
  flex-direction: column;

  .furniture-page-subtitle-content {
    display: flex;
    flex-direction: row;
    h2,
    h3 {
      margin: 4px 0 16px 0;
    }

    .furniture-page-subtitle {
      font-size: 24px;
      color: $color-b-primary;
      margin-right: $sp-8;
      max-width: 100%;
      @include ellip;
      overflow: visible;
    }

    .furniture-page-subtitle-org-name {
      font-size: 24px;
      color: $color-b-secondary;
      max-width: 100%;
      @include ellip;
      overflow: visible;
    }
  }

  .furniture-page-subtitle-info {
    margin: $sp-16 0 0 $sp-8;
  }

  .furniture-page-title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
