// TODOs: Copied from group_chip css class. Would be nice to use same class.
// TODOs: We should avoid !important overrides here if we imported furniture stylesheets before components stylesheets.
// Don't feel comfortable doing that as a side effort in a PR. Should be done intentionally.
.employee-chip {
  margin: 0 $sp-8 $sp-8 0 !important;
  font-weight: 700 !important;
  color: $color-deep-cerulean !important;
  background-color: $color-light-cerulean !important;

  svg {
    color: $color-deep-cerulean !important;
  }
}

.search-results {
  max-height: '280px';
  overflow: 'auto';
}
