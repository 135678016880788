.swatch {
  flex: 1 1 auto; // This needed to make max-width actually do anything
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  height: 100%;

  &.stripe {
    min-width: 24px;
    @include faint-striping;
  }

  &.heatmap {
    min-height: 24px;
    min-width: 24px;
    max-width: 36px;
    border-radius: 4px;
    border: 1px solid white;
  }

  &.full {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
  }
}
