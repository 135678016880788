.coaching-subscription-info-row {
  margin-bottom: $sp-24;

  .coaching-subscription-info-row-title {
    flex: 0 0 180px;
    font-weight: 600;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
