.score-change-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;

  > div + div {
    border-top: $light-grey-border;
    @include ultra-narrow(border-left, $light-grey-border);
  }

  .score {
    font-size: 26px;
    font-weight: bold;
    padding: $sp-8 0;
    color: $color-b-secondary;

    .green {
      color: $color-good;
      font-family: 'Cornbread Honey';
      font-weight: 600;
    }
    .red {
      color: $color-bad;
      font-family: 'Cornbread Honey';
      font-weight: 600;
    }
  }

  &.append-to-chart {
    text-align: right;

    .score {
      font-size: 30px;
      line-height: 20px;
      font-weight: 700;
      padding: 0;
    }

    .since {
      font-size: 14px;
      font-weight: normal;
    }
  }

  &.rebranded-style {
    margin: 26px 0 $sp-16;
  }
}
