.furniture-chips-container {
  display: flex;
  flex-wrap: wrap;
}

.chip-disabled {
  background-color: $color-disabled !important;
}

.tpz-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: $sp-16;
  margin: 0 6px;
  height: 48px;
  font-size: 14px;
  min-width: 144px;
  background-color: $color-tab-hover-gray;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  &.no-interaction {
    cursor: default;
  }

  // remove icon
  svg {
    z-index: 2;
    margin: 0 $sp-4;
    padding: $sp-2;
    border-radius: 8px;
  }

  svg:hover {
    background-color: $color-hover-gray;
  }

  &.has-tooltip {
    cursor: help;
  }

  &.disabled {
    color: $color-b-primary !important;
    background-color: $color-disabled-gray !important;

    svg {
      color: $color-b-primary !important;
    }
  }
}
