.content {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 0 96px $sp-32 $sp-48;
  @include ultra-narrow(padding, $sp-8);

  // avoid adding margin top to the scorecard due to having the group info bar div
  > *:not(.info-bar) + div {
    margin-top: $sp-32;

    @include ultra-narrow(margin-top, $sp-8);
  }
}

// update when engage-rebrand in live.
.rebrand-app {
  height: 100%;
  width: 100%;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: $sp-16 96px $sp-64 $sp-48;
    margin: 0 auto;

    @media screen and (max-width: $bp-xs) {
      padding: 0;
    }
  }
}
