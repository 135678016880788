.group-chip {
  margin: 0 $sp-8 $sp-8 0 !important;
  font-weight: 700;
  color: $color-deep-cerulean;
  background-color: $color-light-cerulean;

  svg {
    color: $color-deep-cerulean !important;
  }
}
