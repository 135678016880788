.assessment-upload-section {
  margin: $sp-24;
}

.assessment-upload-header {
  margin: 0 $sp-24;
  padding: $sp-24 0 0 0;
  font-size: 36px;
}

.assessment-upload-title {
  font-weight: bold;
  margin: $sp-24 0 $sp-8 0;
}

.assessment-upload-switch {
  margin: $sp-24 0 $sp-8 0;
  padding-bottom: $sp-24;
}

.assessment-upload-description {
  margin: $sp-8 0;
  color: $color-stack-gray;
}

.assessment-upload-section-textbox {
  width: 200px;
}

.assessment-upload-likert-statements-header {
  background-color: #f0f0f0;
  padding: $sp-8 $sp-16;

  .statement-header {
    width: 20%;
    padding: $sp-8 $sp-16;
    color: $color-b-disabled;
    font-size: 12px;
    text-align: left;
  }

  .statement-body {
    width: 60%;
  }
}

.statement {
  width: 20%;
  padding: $sp-8 $sp-16;
  border-bottom: $light-grey-border;
  text-align: left;
}

.statement-body {
  width: 60%;
}
