.coaching-session-input {
  max-width: 375px;
}

.coaching-session-times {
  margin-top: $sp-8;
  max-width: 766px;
}

.edit-session-switch {
  padding-top: 8px;
  width: 170px;
}
