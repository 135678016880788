.coaching-notes-heading {
  padding: 28px $sp-16 28px $sp-24;
}

.coaching-notes-heading-bordered {
  border-bottom: 1px solid $color-more-mid-gray;
}

.coaching-notes-date {
  display: flex;
  flex-direction: column;
  flex: 0 0 42px;
  align-items: center;

  .coaching-notes-date-month {
    display: flex;
    flex: 1 1 auto;
    color: $color-destructive-ruby;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .coaching-notes-date-day {
    display: flex;
    flex: 1 1 auto;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
}

.coaching-sessions-detail-card {
  flex: 1 2 auto;
}

.coaching-notes-title {
  flex: 1 1 auto;
  padding-left: $sp-24;
  font-size: 18px;
  line-height: 22px;
  font-weight: $font-bold;
  justify-content: center;

  .furniture-heading {
    margin: 0;
  }

  .coaching-notes-title-sub {
    margin-top: $sp-4;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.coaching-session-detail-focus-areas,
.coaching-session-detail-insights {
  margin-bottom: $sp-24;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  > .session-detail-icon {
    flex: 0 0 25px;
    display: flex;
    align-self: flex-start;
    height: 35px;

    > svg {
      align-self: center;
    }
  }

  > .session-detail-title {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-right: 10px;
    line-height: 35px;
  }
}

.coaching-session-detail-insights-title {
  flex: 0 0 auto;
  align-self: flex-start;
  line-height: 35px;
}

.coaching-notes-content {
  position: relative;
}

.coaching-notes-coach-photo {
  position: absolute;
  top: 24px;
  left: -4px;
  border: 4px solid white;
  border-radius: 4px;
  width: 64px;
  height: 64px;
}

.coaching-notes {
  display: flex;
  flex-direction: column;
  background-color: $color-gray-background;
  border: 1px solid $color-onyx-tint-9;
  border-radius: 4px;
}

.coaching-subscription-notes-body {
  min-height: 70px;
  padding: $sp-24;
}

.coaching-notes-body {
  min-height: 140px;
  margin-left: $sp-28;
  padding: $sp-24 $sp-24 $sp-24 $sp-48;

  .coaching-notes-notes {
    flex: 1 1 auto;

    p {
      margin-top: 0;
    }
  }

  .coaching-notes-confidentiality {
    flex: 0 0 auto;
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
  }
}

.coaching-session-detail-toggle {
  right: $sp-16 !important;
  margin: auto 0;

  button svg {
    color: $color-dark-blue !important;
  }
}

.tpz-chip.coaching-session-detail-focus-areas-chip {
  border-radius: 16px;
  padding: $sp-8 14px $sp-8 $sp-8;
  height: 35px;
  min-width: 48px;
  justify-content: center;

  img {
    height: 24px;
    width: 24px;
  }
}
