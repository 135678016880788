.coaching-dashboard {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bp-2xl) {
    flex-direction: row;
  }
}

.coaching-sidebar-container {
  @media screen and (min-width: $bp-2xl) {
    flex: 0 0 250px;
  }
}

.coaching-dashboard-content {
  flex: 1 1 0%;
  margin-top: $sp-32;

  @media screen and (min-width: $bp-2xl) {
    margin-top: 0;
    margin-left: $sp-32;
  }
}

.sidebar-group-access {
  padding: $sp-12;
  justify-content: center;
  margin: $sp-8 0;
}

.sidebar-employee-name {
  font-weight: 700;
  font-size: 20px;
}

.sidebar-employee-details {
  font-size: 12px;
  font-weight: 700;
  color: $color-stack-gray;
  margin: $sp-16 0;
}

.sidebar-section {
  padding: $sp-12;

  &:not(:last-child) {
    border-bottom: $light-grey-border;
  }
}

.sidebar-section-title {
  color: $color-stack-gray;
  font-weight: 700;
  font-size: 12px;
  margin: $sp-24 0;
  letter-spacing: 1px;
}

.sidebar-coach-image {
  width: 72px;
  height: 72px;
  border-radius: 4px;
}

.sidebar-coach-name {
  width: 150px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 0 $sp-12;
  overflow-wrap: break-word;
}

.coaching-progress {
  padding: $sp-24;
  margin-bottom: $sp-32;
}

.coaching-progress-heading {
  display: flex;
  margin-bottom: $sp-24;

  > .coaching-progress-heading-title {
    align-self: center;
    display: flex;
    flex: 1 1 auto;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    font-weight: 700;
  }

  > .coaching-progress-heading-actions {
    flex: 0 0 90px;

    .tpz-btn {
      min-width: auto;
    }
  }
}

.coaching-progress-body {
  display: flex;
}

.coaching-progress-focus-area {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.6) 37.1%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #beddf1, #beddf1);
  border-radius: 8px;
  padding: $sp-24;
  width: 200px;

  > .coaching-progress-focus-area-title {
    color: #808080;
    font-weight: bold;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    margin-bottom: $sp-24;
    text-transform: uppercase;
    letter-spacing: 0.08em;
  }

  > .coaching-progress-focus-area-icon {
    margin-bottom: $sp-16;
  }

  > .coaching-progress-focus-area-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
}

.progress-metric {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 220px;
  margin-left: $sp-24;
  justify-content: flex-end;
  padding: $sp-24;

  &:first-child {
    margin-left: 0;
  }

  > .progress-metric-title {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: $sp-16;
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    > .progress-metric-title-icon {
      display: flex;
      align-items: center;
      margin-right: $sp-8;
    }
  }

  > .progress-metric-number {
    margin-bottom: $sp-16;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
  }

  > .progress-metric-description {
    font-size: 12px;
    line-height: 12px;
  }
}

.my-sessions-schedule-title {
  margin-top: 0;
  margin-bottom: $sp-32;
  font-size: 20px;
  line-height: 24px;
}

.no-sessions {
  display: flex;
  flex-direction: column;
  gap: $gap-16;
  padding: $sp-28 $sp-24;
  border: 1px dashed #eaeaea;
  border-radius: 4px;
  background-color: #f8f8f8;

  @media screen and (min-width: $bp-md) {
    flex-direction: row;
  }
}

.no-sessions-icon {
  flex: 0 0 66px;
  padding: $sp-4 $sp-24 $sp-4 0;
}

.no-sessions-content {
  flex: 1 1 auto;
  padding-right: $sp-24;
}

.no-sessions-button {
  @media screen and (min-width: $bp-md) {
    flex: 0 0 116px;
  }
}
