.div-layout {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: $sp-12;
  }
}
