.furniture-table {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;

  .clickable {
    cursor: pointer;
  }

  .header-row {
    display: flex;
    flex-direction: row;
    user-select: none;

    .header-field {
      display: flex;
      justify-content: space-between;
      min-width: 0;
      align-items: center;
      font-weight: 600;

      &.active {
        background-color: #f0f0f0;
      }

      .header-span {
        margin-left: $sp-16;
        margin-right: $sp-4;

        &:not(.allow-overflow) {
          @include ellip;
        }
      }

      svg {
        margin-right: $sp-12;
      }

      &.auto-width {
        flex: 1 0 0%;
      }
    }
  }

  // A "row" can be selectable or a regular row
  // We want the caller's experience using these two things to be the same
  // but they are completely separate implementations
  // A selectable row is a flex container that contains both a row and a radio button
  // So there are some overlapping styles, and also an expected order of nesting.
  .row,
  .selectable-row,
  .selectable-row > .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.row-border {
      border-top: $light-grey-border;
    }

    .row-field {
      display: flex;

      &.auto-width {
        flex: 1 0 0%;
      }
    }

    &.nowrap {
      flex-wrap: nowrap;
    }
  }
}

.selectable-row > .row {
  width: 100%;
}

.selectable-row__radio-button {
  align-self: center;
}

.drag-handle-field {
  width: 20px;
}
