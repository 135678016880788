.v2-sub-menu-list-item {
  display: flex;
  flex-direction: row !important;
  font-size: 12px;
  margin: $sp-4 !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px !important;
  text-decoration: none;
  cursor: pointer;
  height: 56px;
  overflow: hidden;
  color: $color-b-primary !important;
  user-select: none;

  &.sub-menu-highlight {
    border-right: 1px solid $color-deep-cerulean;
    border-radius: 0px;
    font-weight: bold;
    font-size: 14px;
    color: $color-deep-cerulean !important;
  }

  &.has-sub-title {
    align-items: unset;
  }

  .v2-sub-menu-list-item-icon {
    padding-right: $sp-8;

    svg {
      width: 5px;
      height: 8px;
    }
  }

  .v2-sub-menu-list-item-right-icon {
    color: inherit !important;
    svg {
      width: 8px;
      height: 5px;
    }
  }

  .v2-sub-menu-list-item-label {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: space-between;
    color: inherit !important; // overrides the nav > div styling from legacy drawer
    font-size: 14px;
    font-weight: 600;

    div {
      color: inherit !important;
    }
  }
}

.drawer-v2-sub-menu {
  flex: 1 0 auto;
}

.drawer-v2-sub-nav {
  width: 224px;
  background-color: $color-w-primary;
  box-shadow: $color-b-12 3px 0px 6px;
  overflow: hidden;
  position: relative;
  height: 100%;
  top: 0;
  overflow-y: scroll;

  .drawer-sub-nav-header {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
  }
}
