.incompatible-browser-banner {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: $color-b-primary;
  background-color: $color-methodology-amber;
  font-size: 18px;
  font-weight: 600;
  padding: $sp-20 0;
  z-index: 2002;
}
