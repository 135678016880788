.tpz-badge {
  font-weight: 600;
  padding: $sp-4 $sp-8;
  height: 24px;
  font-size: 14px;
  background-color: $color-w-primary;
  border: 1px solid $color-gray-80;
  border-radius: 14px;
  user-select: none;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tpz-badge-primary {
  background-color: $color-light-cerulean;
  color: $color-deep-cerulean;
  border: none;

  &.methodology {
    background-color: $color-methodology-amber;
    color: $color-darker-amber;
  }
  &.success {
    background-color: $color-success-light-emerald;
    color: $color-darker-green;
  }
  &.warning {
    background-color: $color-background-amber;
    color: $color-b-primary;
  }
  &.destructive {
    background-color: $color-destructive-light-ruby;
    color: $color-darker-ruby;
  }
  &.expired {
    background-color: $color-disabled-gray;
    color: $color-b-primary;
  }
}

.tpz-badge-secondary {
  background-color: transparent;
  color: $color-deep-cerulean;
  border: 1px solid $color-deep-cerulean;

  &.success {
    color: $color-darker-green;
    border: 1px solid $color-success-emerald;
  }
  &.warning {
    color: $color-darker-amber;
    border: 1px solid $color-methodology-amber;
  }
  &.destructive {
    color: $color-darker-ruby;
    border: 1px solid $color-destructive-light-ruby;
  }
  &.light {
    color: black;
    border: 1px solid $color-more-mid-gray;
    background-color: white;
  }
}

.tpz-badge-tertiary {
  background-color: transparent;
  color: $color-deep-cerulean;
  border: none;

  &.success {
    color: $color-success-emerald;
  }
  &.warning {
    color: $color-deep-cerulean;
  }
  &.destructive {
    color: $color-destructive-ruby;
  }
}
