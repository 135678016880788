.enps-st-title {
  @include ultra-narrow(padding, 0 $sp-8);

  > h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;

    .details {
      font-weight: normal;
      font-size: 16px;
    }

    .toggle-details {
      padding-left: $sp-8;
      font-weight: normal;
      font-size: 16px;
    }
  }

  > p {
    font-size: 16px;
    font-style: italic;
  }
}

.enps-breakdown-section-title {
  padding: 0 $sp-24;
  @include ultra-narrow(padding, 0 $sp-8);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;

  .title {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    h3 {
      flex: 1 0 auto;
    }

    h3,
    .response-rate {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      padding-top: $sp-24;
      // When wrapping the key underneath, we do not want padding between the title and the key
      // So moved the bottom padding to the flex rather than the item
    }
  }

  > p {
    margin: 0;
    padding-bottom: $sp-24;
    font-size: 16px;
    line-height: 20px;
    @include ultra-narrow(padding-bottom, 0);
  }
}

.enps-breakdown-section-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // Override the default Card box-shadow
  box-shadow: none !important;
  border-left: $light-grey-border;
}

.enps-breakdown-section-legend {
  display: flex;
  flex-direction: column;

  .legend {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    margin-top: 0;
    // Roughly detect when we have to wrap the legend
    @include ultra-narrow(padding-bottom, $sp-16);
  }
}

.enps-st-tbl {
  position: relative;
}

.enps-st-tbl-hdr {
  background-color: #f0f0f0;
  font-size: 14px;
  padding: $sp-8 $sp-24;
  // For the initial hacky version, no header on mobile
  @include ultra-narrow(display, none !important);

  > .enps-st-tbl-question {
    flex: 1 0 auto;
  }

  > .enps-st-tbl-hdr-ldr {
    display: flex;
    justify-content: flex-end;
    padding-right: 124px;
  }

  > .enps-st-tbl-detractor,
  > .enps-st-tbl-passive,
  > .enps-st-tbl-promoter {
    flex: 0 0 140px;
  }

  > .enps-st-tbl-detractor {
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 416px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }
  > .enps-st-tbl-passive {
    text-align: center;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 235px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }
  > .enps-st-tbl-promoter {
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 45px;
      width: 1px;
      background-color: $color-faint-gray;
      content: ' ';
    }
  }

  > .enps-st-tbl-hdr-0,
  > .enps-st-tbl-hdr-25,
  > .enps-st-tbl-hdr-50,
  > .enps-st-tbl-hdr-75,
  > .enps-st-tbl-hdr-100 {
    flex: 0 0 84px;
    text-align: center;
  }

  > .enps-st-tbl-hdr-0 {
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 443px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .enps-st-tbl-hdr-25 {
    padding-left: 10px;
    text-align: left;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 335px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .enps-st-tbl-hdr-50 {
    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 233px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .enps-st-tbl-hdr-75 {
    padding-right: 10px;
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 135px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }

  > .enps-st-tbl-hdr-100 {
    text-align: right;

    &:after {
      position: absolute;
      top: 30px;
      bottom: 0;
      right: 24px;
      width: 1px;
      background-color: #f0f0f0;
      content: ' ';
    }
  }
}

.enps-st-tbl-body {
  .enps-st-tbl-row {
    padding: $sp-24;
    @include ultra-narrow(padding, $sp-24 0);
  }

  .enps-st-tbl-row:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.enps-st-tbl-row {
  // We want the bar below the row on mobile
  @include ultra-narrow(flex-wrap, wrap);
  .enps-st-tbl-row-question {
    flex: 1 1 auto;
    line-height: 22px;
    font-size: 16px;
    padding: 6px $sp-24 6px 0;
    @include ultra-narrow(padding, 6px $sp-8);
  }

  .enps-st-tbl-row-ldr {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 84px;
    margin-right: $sp-64;

    > .detractor,
    > .passive,
    > .promoter {
      width: 84px;
      border: 1px solid white;
      padding: 9px $sp-12;
      text-align: center;
      border-radius: 4px;
      max-height: 34px;
    }

    .detractor {
      background-color: $color-assessment-detractor;
    }

    .passive {
      background-color: $color-assessment-passive;
    }

    .promoter {
      background-color: $color-assessment-promoter;
    }
  }
}

.enps-st-tbl-row-result {
  display: flex;
  @include ultra-narrow(padding, 0 $sp-8);

  .total-respondents {
    display: flex;
    min-width: 34px;
    padding-left: 6px;
  }
}

.enps-st-likert-breakdown {
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  z-index: 100;
  position: relative;
  border-radius: 4px;

  > .detractor,
  > .passive,
  > .promoter {
    border: 1px solid white;
    padding: 9px $sp-12;
    text-align: center;
    align-items: center;
  }

  > div:first-child {
    border-radius: 4px 0 0 4px;
  }

  > div:last-child {
    border-radius: 0 4px 4px 0;
  }

  .detractor {
    background-color: $color-assessment-detractor;
  }

  .passive {
    background-color: $color-assessment-passive;
  }

  .promoter {
    background-color: $color-assessment-promoter;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: around;
  font-size: 13px;
  @include ultra-narrow(margin, 0);

  .key {
    font-weight: 600;
  }

  > .detractor,
  > .passive,
  > .promoter {
    display: inline-block;
    padding-right: 10px;
  }

  .circle {
    border-radius: 50%;
    width: 13px;
    height: 13px;
    @include ultra-narrow(width, 12px);
    @include ultra-narrow(height, 12px);
    display: inline-block;
    margin-right: $sp-16;
    margin-left: $sp-16;

    @include ultra-narrow(margin-right, $sp-8);
    @include ultra-narrow(Margin-left, $sp-8);

    &.detractor {
      background-color: $color-assessment-detractor;
    }

    &.passive {
      background-color: $color-assessment-passive;
    }

    &.promoter {
      background-color: $color-assessment-promoter;
    }
  }
}

.response-rate-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $sp-40 $sp-24;
  font-size: 18px;
  line-height: 22px;
}

.enps-score-and-breakdown-container {
  display: flex;
  flex-direction: row;
  @include narrow(flex-direction, column);

  .enps-score-section {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    min-width: 200px;
    position: relative;

    @include wide(flex, 1);
    @include narrow(flex, 1 0);
    @include ultra-narrow(flex, 0 0 auto);
    @include ie11-and-narrow(flex, none);

    .enps-score {
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      margin-top: 30px;
      font-size: 45px;
      font-weight: 600;
    }

    .chatter-icon {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .enps-info-button {
      height: 56px;
      width: 56px;
    }
  }
}

.enps-info {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  background: $color-info-bg;
  padding: 0 $sp-16;
}

.export-button-heading {
  position: relative;

  > .export-button {
    position: absolute;
    z-index: 10;
    right: 0;
  }
}
