.participation-with-trendline {
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  @include ie11-and-narrow(flex, none);

  .title {
    font-size: 19px;
    font-weight: 700;
    margin-top: $sp-32;
    margin-bottom: $sp-16;
  }

  .percentage {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: $sp-16;
    font-family: 'Cornbread Honey'; 
  }

  .ratio {
    font-size: 18px;
    color: #7f7f7f;
  }

  .participation-top {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include ie11-and-narrow(flex, none);

    .participation-hidden-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      img {
        // Scale up 2x from the original 24px svg
        width: 48px;
        height: 48px;
      }

      .participation-hidden-spacer {
        height: 10px;
      }
    }
  }
}
