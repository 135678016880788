.education-tooltip-root {
  // Remove ugly transitions that cause borders to show before animation is finished
  background: transparent !important;
  overflow: visible !important;
  box-shadow: none !important;
  // This positions the popover way offscreen, which is then overridden (fixes flashing)
  left: -31415px;

  > div {
    overflow: visible !important;
    > div {
      overflow: visible !important;
    }
  }
}

.education-vocab {
  border-bottom: 1px dashed hsla(0, 0%, 0%, 0.25);
  cursor: help;
  position: relative;
  z-index: 100;
}

.authority {
  border-bottom: none;
}

.education-vocab-tooltip {
  background-color: $color-darkest-blue;
  color: $color-w-primary;
  font-size: 18px;
  line-height: 1.3;
  width: 350px;
  border-radius: 2px;
  padding: $sp-2 $sp-24 $sp-24;
  box-shadow: hsla(0, 0%, 0%, 0.12) 0px 1px 6px,
    hsla(0, 0%, 0%, 0.12) 0px 1px 4px;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  h3,a {
    color: $color-w-primary;
  }
}

.vocab-prompt-title {
  margin: $sp-24 0;
  font-size: $sp-16;
  font-weight: $font-semibold;
  color: $color-black;
}

.vocab-prompt-definition {
  margin: $sp-8 0;
  font-size: $sp-16;
  font-weight: light !important;
  color: $color-black;
  line-height: $sp-24;
}

.vocab-tooltip-list {
  padding: $sp-24 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: outside;

    > li {
      @include drawer-open(padding-top, $sp-8);
      // Special handling of mobile tooltips for orientation
      @media screen and (orientation: portrait) {
        @include drawer-closed(padding-top, $sp-8);
      }
      @media screen and (orientation: landscape) {
        @include drawer-closed(padding-top, $sp-4);
      }
      margin-left: $sp-16;
    }
  }
}

.learn-more-vocab {
  margin-top: $sp-16;
  @include ultra-narrow(margin-top, $sp-8);
}

.mobile-click-away {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
}
