.campaign-table-card-header {
  padding: $sp-24 $sp-24 0 $sp-24;
  flex-wrap: wrap;

  h3 {
    font-family: 'Cornbread Honey';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }

  .campaign-list-action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: $sp-16;
  }
}

.selected-surveys {
  padding-top: 0;
}

.campaign-table-header-row {
  display: flex;
  align-items: center;
  padding: $sp-16;
  font-size: 14px;
  background-color: $color-gray-background;
  font-weight: 500;

  .header-field {
    display: flex;
    align-items: center;
  }
}

.heading-with-icon {
  display: flex;
  align-items: center;
}

.campaign-section-heading-text {
  padding-left: 8px;
}

.campaign-table-row {
  display: flex;
  align-items: center;
  padding: $sp-16;
  border-bottom: 1px solid $color-faint-gray;
  background-color: $color-w-primary;
  font-size: 14px;
  font-weight: $font-normal;
}

.campaign-detail-input {
  max-width: 350px;
  width: 100%;

  .furniture-heading {
    margin-top: $sp-12;
  }
}

.campaign-details-title {
  font-family: 'Cornbread Honey';
  font-size: 26px;
  margin: $sp-16 0 0 0;
  font-weight: $font-semibold;
}

.campaign-details-title-subtext {
  font-size: $sp-16;
  font-weight: $font-semibold;
  line-height: 24px;
  margin-top: 0;
}

.link-color {
  color: $color-action-secondary-500;
}

.campaign-notification-input {
  width: 240px;
}

.campaign-schedule-time {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  min-height: 125px;
  margin-top: $sp-16;
}

.campaign-add-notification {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: $sp-24;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: $sp-8;
}

.campaign-add-notification-buttons {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}

.campaign-add-notification-button {
  min-width: 180px;
  margin-top: $sp-8;
}

.campaign-more-button {
  max-width: 20px;
}

.campaign-statement-body {
  background-color: $color-gray-background;
  padding: $sp-16;
  margin: 0 $sp-16 $sp-16 $sp-16;
}

.campaign-statement {
  display: flex;
  align-items: center;
  min-height: 83px;
  margin: $sp-12;
  background-color: $color-w-primary;
}

.status-alert-icon {
  margin-right: $sp-12;
  cursor: help;
  margin-left: $sp-16;
}

.status-chip-container {
  display: flex;
  align-items: center;

  .status-chip {
    height: auto;
    min-width: auto;
    margin: 0;
    margin-right: 6px;
    padding: 6px;
    font-weight: 500;
    border-radius: 24px;
  }

  .pending {
    color: $color-ruby-800;
    background-color: $color-ruby-200;
  }

  .active,
  .prepared {
    color: $color-navy-100;
    background-color: $color-amber-300;
  }

  .inprogress {
    color: $color-emerald-800;
    background-color: $color-success-light-emerald;
  }

  .finished {
    color: $color-emerald-900;
    background-color: $color-emerald-200;
  }

  .errored {
    color: $color-darker-ruby;
    background-color: $color-w-primary;
    border: 1.5px solid $color-darker-ruby;
  }
}

.campaign-info-header {
  border: none;
  border-radius: $sp-4;
  flex: 1 1 500px;
  min-width: 400px;
  padding-left: $sp-24;
}

.campaign-info-header-spacing {
  margin-right: $sp-8;
  @include narrow(margin-right, 0);
  @include narrow(margin-bottom, $sp-8);
  @include drawer-closed(margin-right, 0);
  @include drawer-closed(margin-bottom, $sp-8);
}

.card-header {
  align-items: stretch !important;
  justify-content: center;
}

.campaign-onboarding-image {
  margin: $sp-16 0;
}

@media screen and (max-width: 700px) {
  .campaign-onboarding-image {
    display: none;
  }
}

.onboarding-links {
  font-size: $sp-16;
  color: $color-action-secondary-500;
}

.campaign-onboarding-list {
  margin-top: 0;
}

.campaign-onboarding-list-items {
  font-size: 20px;
}

.first-campaign-text {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  font-family: 'Cornbread Honey' !important;
}

.campaign-onboarding-item {
  font-size: $sp-16;
  padding: 4px;
}

.campaign-onboarding-header-title {
  padding: $sp-16 $sp-16 0 $sp-16;
  font-size: 20px;
  justify-content: center;
  text-align: center;
}

.campaign-initial-instructions-title {
  font-size: 20px;
}

.campaign-info-container {
  padding: $sp-16 0;
  display: flex;
  margin-bottom: 0;

  .campaign-info-header-image {
    flex: 1 1 0;
    margin: auto;
  }

  @media screen and (max-width: $bp-sm) {
    flex-direction: column;
  }
}

.campaign-info-header-title {
  color: $color-navy-100;
  font-family: 'Cornbread Honey' !important;
  font-size: 26px;
  padding: 0 $sp-16 $sp-16 0;
  flex: 2 1 0;
}

.campaign-info-header-subtitle {
  font-size: $sp-16;
  font-weight: 500;
  color: $color-navy-200;
  padding: 0 $sp-16 0 0;
}

.campaign-onboarding-header-subtitle {
  padding: 0 $sp-16;
  display: flex;
  flex: 1;
  height: 100%;
}

.campaign-onboarding-header {
  width: 100%;
}

.add-campaign-button-container {
  margin: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.confirm-row-icon-wrapper {
  display: flex;
  justify-content: center;
  min-height: 26px;
  min-width: 33px;
}

.confirm-campaign-modal {
  display: flex;
}
.confirm-campaign-modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: $sp-8 auto;
  text-align: center;
  width: 100%;
}

.confirm-campaign-modal-content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-campaign-header {
  flex-direction: column;
  align-items: center;
}

.confirm-campaign-table-container {
  overflow-x: visible;
}

.confirm-campaign-table {
  border: 1px solid $color-more-mid-gray;
  max-width: 450px;
  margin-bottom: $sp-16;
  height: 100%;

  tr {
    border-bottom: none;
  }
}

.confirm-campaign-description {
  margin: $sp-16;
  max-width: 550px;
}

.confirm-campaign-footer {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  align-self: flex-end;
}

.confirm-campaign-table-label {
  display: flex;
  align-items: center;
}

.confirm-campaign-table-description {
  white-space: normal;
}

.campaign-details-lower {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;
  flex-wrap: wrap;
  align-items: baseline;
  padding-top: $sp-8;
}

.campaign-details-comments {
  display: flex;
  flex: 1;
  width: 100%;
  margin-bottom: $sp-8;
  flex-direction: row;
  align-items: flex-start;
  min-height: 80px;
}

.view-comments-dropdown {
  display: block;
  min-width: 260px;
  padding-left: 8px;
}

.view-comments-text {
  margin: $sp-16 $sp-24 $sp-12 $sp-8;
}
.campaign-details-participants {
  display: flex;
  flex: 1;
  min-width: 300px;
  align-items: center;
  margin-bottom: $sp-8;
  flex-wrap: wrap;
}

.campaign-name-input {
  max-width: 400px;
  width: 100%;
  font-weight: 500;
}

.campaign-repeat-info-container {
  display: flex;
  height: 40px;
}

.campaign-no-repeat-option {
  white-space: nowrap;
  font-size: $sp-16;
  margin-bottom: $sp-24;
  font-weight: 500;
}

.campaign-section-text {
  font-size: $sp-16;
  font-weight: 700;
  margin-left: 8px;
}

.campaign-section-subtext {
  font-size: 14px;
  font-weight: $font-normal;
  margin: -8px 0 0 28px;
}

.campaign-target-subtext {
  margin: -8px 0 0 10px;
}

.campaign-repeat-subtext {
  margin: -8px 0 24px 12px;
}

.campaign-repeat-option-label-text {
  font-size: $sp-16;
  padding: 0 $sp-8;
  font-weight: 500;
}

.campaign-repeat-option-no-left-padding {
  padding-left: 0;
}

.campaign-repeat-days-input {
  width: 70px;
  margin-top: 10px;
}

.error {
  outline: 1px solid red;
  border-radius: 8px;
}

.campaign-repeat-option-label-text-disabled {
  color: $color-disabled;
}

.campaign-info-button {
  display: flex;
  align-items: center;
  padding-top: $sp-8;
  margin-left: $sp-4;
}

.campaign-repeat-body-structure {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.no-actions-tooltip {
  background-color: $color-darkest-blue;
  color: $color-w-primary;
  font-size: 18px;
  line-height: 1.3;
  max-width: 350px;
  min-width: 250px;
  border-radius: 2px;
  padding: $sp-8 $sp-16;
}

.campaign-repeat-wrapper {
  width: 100%;
  padding-top: 32px;
}

.campaign-repeat-radio-buttons {
  display: flex;
  margin-top: $sp-16;
  margin-left: 38px;
  flex-direction: column;
}

.campaign-repeat-option-container {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding-bottom: $sp-8;
}

.participation-tooltip {
  background-color: $color-darkest-blue;
  color: $color-w-primary;
  font-size: 18px;
  line-height: 1.3;
  max-width: 350px;
  border-radius: 2px;
  padding: $sp-8 $sp-16;
}

.in-progress-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.response-rate-link {
  font-weight: 700;
  padding: $sp-8;
  color: black;
  text-decoration: none;
  margin-top: $sp-8;
}
.response-rate-link:hover {
  text-decoration: underline;
}

.manage-employee-link {
  padding: 0 $sp-16;
  white-space: nowrap;
}

.campaign-targeting-info-container {
  display: block;
}

.campaign-no-targeting-option {
  width: 30%;
  font-size: 14px;
  font-weight: 600;
}

.campaign-targeting-option {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: $sp-12;
}

.targeted {
  margin-top: 12px;
}

.group-select {
  margin-left: 24px;
}

.exclude-employees-text,
.include-employees-text {
  font-size: 14px;
  font-weight: $font-normal;
}

.exclude-employees-subtext,
.include-employees-subtext {
  font-size: $sp-12;
  font-weight: $font-normal;
}

.targeting-option-subtext {
  font-size: $sp-12;
  font-weight: $font-normal;
  margin-left: $sp-24;
}

.select-container-300 {
  width: 300px;
}

.select-container-450 {
  width: 450px;
}

.select-container-500 {
  width: 500px;
}

.feedback-select-container {
  margin-left: 8px;
}

.campaign-option-subtext {
  font-size: 14px;
  margin-left: $sp-12;
  margin-top: $sp-8;
}

.campaign-option-subtext-no-margin-bottom {
  @extend .campaign-option-subtext;
  margin-bottom: 0;
}

.campaign-targeting-option-label-text {
  font-size: $sp-16;
  padding: 0 $sp-8;
}

.campaign-targeting-option-no-left-padding {
  padding-left: 0;
}

.campaign-targeting-days-input {
  width: 80px;
}

.campaign-targeting-option-label-text-disabled {
  color: $color-disabled;
}

.campaign-info-button {
  display: flex;
  align-items: center;
  padding-top: $sp-8;
  margin-left: $sp-4;
}

.campaign-targeting-body-structure {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.no-actions-tooltip {
  background-color: $color-darkest-blue;
  color: $color-w-primary;
  font-size: 18px;
  line-height: 1.3;
  max-width: 350px;
  min-width: 250px;
  border-radius: 2px;
  padding: $sp-8 $sp-16;
}

.campaign-targeting-wrapper {
  width: 100%;
  margin: $sp-16 0 0 0;
}

.campaign-targeting-radio-buttons {
  display: block;
  margin: 0 38px;
}

.exclusion-container,
.include-container {
  padding-top: $sp-2;
  margin-left: 38px;
}

.targeting-dropdown {
  display: flex;
  align-items: center;
  margin: 0;
}

#feedbackGroupId {
  z-index: 0;
}

// update when engage-rebrand is live.
@media screen and (min-width: $bp-md) {
  .rebrand-app .campaign-info-header-spacing {
    margin-bottom: $sp-8;
    margin-right: 0;
  }
}

@media screen and (max-width: 550px) {
  .campaign-table-card {
    min-width: 400px;
  }
  .campaign-content {
    min-width: 400px;
    margin-right: 32px;
  }
  .campaign-info-header {
    display: hidden;
    padding-right: $sp-16;
  }
}

.clear-all-option {
  color: blue;
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.clickable-row-item {
  text-decoration: underline;
  cursor: pointer;
}

.date-field {
  padding: 0 $sp-16;
}

.results-button {
  width: max-content !important;
}

.campaign-option-subtext-div {
  height: 4px;
}

.campaign-list-title {
  font-weight: 600;
  font-size: 22px;
  color: $color-navy-100;
}

.campaign-notification-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.campaign-notification-tooltip {
  min-width: 200px;
}

.campaign-survey-selection {
  display: flex;
  align-items: end;
  margin: 0 $sp-16;
  flex-wrap: wrap;
}

.campaign-survey-selection-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: $sp-16;
  justify-content: space-between;
}

.default-repeat-days-text {
  color: $color-tide-170;
  text-decoration: underline;
  cursor: pointer;
}

.confirm-button-container {
  display: flex;
  justify-content: flex-end;
}

.hidden {
  display: none;
}

.survey-table-top-container {
  display: flex;
  justify-content: space-between;
  padding: $sp-24 $sp-16 $sp-4;
}
.survey-preview-container {
  display: flex;
  justify-content: flex-start;

  .survey-total-question-text {
    margin: $sp-12 0 $sp-4;
    color: $color-tide-200;
    font-weight: 500;
  }

  .survey-preview-link {
    line-height: 18px;
    margin: $sp-12 0 0 $sp-4;
  }
}

@media screen and (max-width: 920px) {
  .campaign-survey-selection-container {
    display: block;
    padding-top: $sp-16;
  }
}

.info-bar-text {
  padding-right: $sp-8;
}

.campaign-quick-start-container {
  padding: 0;

  .campaign-quick-start-context-text {
    font-weight: 500;
    color: $color-tide-200;
    display: flex;
  }
  .express-button-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0;
  }
  .express-button {
    margin: $sp-8 $sp-12 0 0;
    padding: 0 $sp-24;
  }
}

.campaign-quick-start-modal-button {
  margin: $sp-8 $sp-12 0 0;
}

.campaign-quick-start-modal-button-cancel {
  margin-right: 170px;
}

@media screen and (max-width: $bp-md) {
  .campaign-quick-start-modal-button-cancel {
    margin-right: $sp-12;
  }
}

.campaign-quick-start-modal-container {
  .campaign-quick-start-modal-title-section {
    border-bottom: 1px solid $color-tide-120;
    text-align: center;

    .campaign-quick-start-modal-title {
      font-size: 22px;
      font-weight: $font-semibold;
      margin: $sp-8 0;
      color: $color-tide-200;
    }

    .campaign-quick-start-modal-subtitle {
      font-size: 14px;
      font-weight: $font-normal;
      margin: $sp-8 0 $sp-16 0;
      color: $color-tide-200;
    }
  }

  .campaign-quick-start-modal-details-section-container {
    margin-bottom: $sp-24;
  }

  .campaign-quick-start-modal-details-section-header {
    font-size: $sp-16;
    font-weight: $font-semibold;
    margin: $sp-32 0 $sp-8;
    color: $color-tide-200;
  }

  .campaign-quick-start-modal-details-section-text {
    font-size: $sp-16;
    font-weight: $font-normal;
    margin: $sp-12 0;
    color: $color-tide-180;
    max-width: 600px;
    line-height: 22px;
  }

  .campaign-quick-start-modal-details-section-text-time {
    font-size: 14px;
    font-weight: $font-normal;
    margin-bottom: $sp-8;
    color: $color-tide-170;
    margin: $sp-8 $sp-8 $sp-16;
    padding-bottom: $sp-16;
  }

  .campaign-quick-start-date-time-container {
    width: 220px;
  }
}

.campaign-quick-start-modal-customize-section {
  border-top: 1px solid $color-tide-120;
}

.campaign-hire-date-container {
  display: flex;
  align-items: center;
  margin: $sp-8 $sp-16;
}
