.coaching-progress-stats {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: $gap-8;

  .coaching-progress-stats-card {
    display: flex;
    flex-direction: column;
    flex: 1 1 150px;
    justify-content: space-between;
    padding: $sp-16;
    font-size: 16px;

    .count {
      font-size: 40px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0px;
      color: $color-b-primary;
      padding-bottom: $sp-16;
    }

    // aligning with text
    .view-button {
      height: 16px;
      padding: 0;
    }
  }
}

.session-type-tooltip {
  max-width: 350px;
}
