.heatmap-tooltip-card {
  display: flex;
  flex-direction: column;
  background-color: $color-w-primary;
  color: $color-b-primary;
  font-size: 18px;
  line-height: 1.3;
  width: 360px;
  min-height: 75px;
  border-radius: 2px;
  box-shadow: hsla(0, 0%, 0%, 0.12) 0px 1px 6px,
    hsla(0, 0%, 0%, 0.12) 0px 1px 4px;

  .trendline-axis {
    margin: 0 $sp-16 0 $sp-32;
  }
}

.heatmap-tooltip-info {
  display: flex;
  border-bottom: $light-grey-border;

  .table-field-score {
    flex: none;
    width: 72px;
    height: inherit;

    > div {
      border-radius: 2px 0 0 0;
    }
  }
}

.heatmap-tooltip-labels {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: $sp-16;

  h5 {
    font-size: 22px;
    color: $color-b-primary;
    max-height: 4em;
    max-width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  h6 {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    color: $color-b-secondary;
  }
}

.heatmap-tooltip-change {
  flex: 0 0 85px;
  margin: 0 !important;
  padding-right: $sp-16;

  .percent {
    padding: 0 !important;
  }
}
