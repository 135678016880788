.fifteen-five-emplify-split-logo-border {
  position: relative;
  margin: 0 $sp-8;

  &:after {
    content: '';
    background: black;
    position: absolute;
    height: 70%;
    width: 1px;
  }
}

.delete-campaign-button {
  text-decoration: underline;
}
