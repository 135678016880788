.furniture-switch {
  display: flex;
  align-items: baseline;
}

.tpz-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: $sp-16;
  height: 48px;
  font-size: 14px;
  min-width: 144px;
  background-color: $color-w-primary;
  border: 1px solid $color-gray-80;
  border-radius: $sp-4;
  cursor: pointer;
  user-select: none;
  max-width: 250px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-icon-no-label {
    justify-content: center;
    padding: 0 $sp-8;
    min-width: 48px;
    max-width: 48px;
  }

  &-icon-with-label {
    span {
      padding-left: 6px;
    }
  }
}

.tpz-btn-primary {
  color: $color-white;
  background-color: $color-navy-200;
  padding: $sp-8 $sp-12;

  svg {
    color: $color-white !important;
  }

  &:hover {
    background-color: $color-tide-170;
  }

  &:active {
    background-color: $color-tide-200;
  }

  &.disabled {
    background-color: $color-tide-110;
    border-color: $color-tide-110;
    color: $color-tide-170;
    cursor: help;
    padding: $sp-8 $sp-12;

    svg {
      color: $color-b-primary !important;
    }
  }
  &.methodology {
    color: $color-w-primary;
    background-color: $color-rise-200;
    border-color: $color-rise-200;
    svg {
      color: $color-w-primary !important;
    }
    &:hover {
      background-color: $color-rise-100;
      border-color: $color-rise-100;
    }

    &:active {
      background-color: $color-rise-300;
      border-color: $color-rise-300;
    }
  }
  &.success {
    background-color: $color-success-emerald;
    color: $color-b-primary;
    svg {
      color: $color-b-primary !important;
    }
  }
  &.warning {
    background-color: $color-methodology-amber;
    color: $color-b-primary;
    svg {
      color: $color-b-primary !important;
    }
  }
  &.destructive {
    background-color: $color-destructive-ruby;
    color: $color-w-primary;
    svg {
      color: $color-w-primary !important;
    }
  }
}

.tpz-btn-secondary {
  background-color: transparent;
  color: $color-tide-200;
  border: 1px solid $color-tide-170;

  svg {
    color: $color-tide-200;
  }

  &:hover {
    color: $color-tide-170;

    svg {
      color: $color-tide-170;
    }
  }

  &:active {
    background-color: $color-tide-110;
  }
  &.disabled {
    color: $color-b-primary;
    border: 1px solid $color-disabled-gray;
    cursor: help;

    svg {
      color: $color-b-primary !important;
    }
  }
  &.success {
    color: $color-b-primary;
    border: 1px solid $color-success-emerald;

    svg {
      color: $color-b-primary !important;
    }
  }
  &.warning {
    color: $color-b-primary;
    border: 1px solid $color-methodology-amber;
    svg {
      color: $color-b-primary !important;
    }
  }
  &.destructive {
    color: $color-destructive-ruby;
    border: 1px solid $color-destructive-ruby;

    svg {
      color: $color-destructive-ruby !important;
    }
  }
  &.light {
    color: black;
    border: 1px solid $color-more-mid-gray;
    background-color: white;

    svg {
      color: black !important;
    }
  }
}

.tpz-btn-tertiary {
  background-color: transparent;
  color: $color-tide-200;
  border: none;
  &:hover {
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }

  svg {
    color: $color-tide-200;

    path {
      stroke: $color-tide-200;
    }
  }

  &.disabled {
    color: $color-b-primary;
    cursor: not-allowed;

    &:hover {
      cursor: not-allowed !important;
      span {
        text-decoration: none;
      }
    }

    svg {
      color: $color-b-primary !important;

      path {
        stroke: $color-b-primary !important;
      }
    }
  }
  &.success {
    color: $color-success-emerald;

    svg {
      color: $color-success-emerald !important;

      path {
        stroke: $color-success-emerald !important;
      }
    }
  }
  &.warning {
    color: $color-tide-200;
    svg {
      color: $color-tide-200;

      path {
        stroke: $color-tide-200;
      }
    }
  }
  &.destructive {
    color: $color-destructive-ruby;

    svg {
      color: $color-destructive-ruby !important;

      path {
        stroke: $color-destructive-ruby !important;
      }
    }
  }
}

.tpz-btn-navigation {
  justify-content: flex-start;

  > .tpz-btn-label {
    flex: 1 1 0%;
  }
}

.tpz-btn-file {
  position: relative;

  > .tpz-btn-file-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 2000;
  }
}

.furniture-v2-check-button:active {
  background-color: $color-w-primary;
}

.furniture-v2-check-button-checked {
  color: $color-blue;
  background-color: $color-blue-hover;
  border: 1px solid $color-blue;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.furniture-spin-button {
  display: flex;
  flex-direction: column;

  .furniture-spin-arrow {
    height: 16px;
    cursor: pointer;

    svg:hover {
      path {
        stroke-width: 3;
      }
    }
  }
}

.radio-button-content {
  display: flex;

  .radio-button-container {
    display: inline-flex;
    position: relative;
    padding-left: $sp-24;
    cursor: pointer;

    input {
      display: none;
    }
  }

  .radio-button {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-w-primary;
    border-radius: 50%;
    border: 1px solid $color-b-secondary;
    height: 16px;
    width: 16px;

    &.disabled {
      pointer-events: none;
      background-color: $color-b-12;
      border: 1px solid $color-b-12;
    }
  }

  .radio-button-container input:checked ~ .radio-button {
    height: 16px;
    width: 16px;
    background-color: $color-action-secondary-500;

    &.disabled {
      pointer-events: none;
      background-color: $color-b-12;
      border: 1px solid $color-b-12;
    }
  }

  .radio-button:after {
    content: '';
    display: none;
  }

  .radio-button-container input:checked ~ .radio-button:after {
    display: block;
  }

  .radio-button-container .radio-button:after {
    margin-top: $sp-4;
    margin-left: $sp-4;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $color-w-primary;
  }

  .radio-button-info {
    display: inline-flex;
    margin-top: 1px;
  }

  .radio-button-label-container {
    display: flex;
    flex-direction: column;
  }

  .radio-button-description {
    margin: 14px 0;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-gray-80;
  transition: 300ms;
  border-radius: $sp-24;
}
.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  top: 2px;
  left: 2px;
  background-color: $color-w-primary;
  transition: 300ms;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: $green-500;
}

.switch input:checked + .slider:before {
  transform: translateX(12px);
}

.switch-description {
  display: inline-block;
  padding-right: 10px;
}
.switch-label {
  display: inline-block;
  position: absolute;
  left: 34px;
  top: 1px;
}

.switch-container {
  display: inline-flex;
}

.furniture-button-tooltip {
  max-width: 150px;
  line-height: 15px;
  font-size: 14px;
  box-shadow: 0 0 $sp-8 $sp-2 $color-b-12;
}
