.d3-org-tree-container {
  display: flex;
  justify-content: center;
  padding: $sp-20;
}

.d3-org-tree-svg {
  width: 900px;
  height: 370px;
}

.node {
  fill: $color-b-primary;
}

.link {
  fill: none;
  stroke: $color-gray-80;
  stroke-width: 1px;
}

.add-new-group-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin-top: 30px;
}

.add-new-group-title {
  font-size: 20px;
}

.ff-managed-tooltip-span {
  border-bottom: none;
}

.group-type-table-title {
  font-family: 'Cornbread Honey' !important;
  font-size: $sp-20 !important;
  font-weight: $font-semibold;
}
