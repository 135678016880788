.publish-dialog-body {
  padding: 0 $sp-24;
}

.publish-dialog-header-row {
  border-top: $light-grey-border;
  border-bottom: $light-grey-border;
  padding: $sp-12 $sp-24;
  font-weight: 600;
  color: $color-b-primary;
}

.publish-dialog-overflow-scroll {
  overflow-y: auto;
  max-height: 200px;
}

.publish-dialog-row {
  padding: $sp-12 $sp-24;
}
