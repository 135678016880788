.table-field {
  padding: 0 $sp-16;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-field-category {
  padding: 0 $sp-16 0 $sp-8;
}

.table-field-score {
  width: 90px;
  height: 100%;
}

.table-field-simple-score {
  width: 90px;
  height: 100%;

  flex: 1 1 auto;

  font-size: 18px;
  font-weight: 700;

  display: flex;
  align-items: center;
  padding-left: $sp-16;
}

.table-field-percentage {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: $sp-16;
}

.table-field-no-value {
  font-size: 22px;
  font-weight: 600;
}

/**
 * We "manually" style text to look like a link here because of a special use case
 * Often we want an entire table row to be clickable. The text itself is not a link, but we want
 * to visually make some text inside the row look like a link to draw the user's attention to
 * the fact that the row is clickable.
 */
.table-field-text-link {
  color: $color-deep-cerulean;
  text-decoration: underline;
  border-bottom: none;
}

.table-category-vocab {
  margin-right: $sp-16;
}
