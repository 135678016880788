.furniture-tooltip-wrapper {
  z-index: 1002;
  color: $color-w-primary;

  .furniture-tooltip {
    padding: $sp-8;
    max-width: 500px;
    background: $color-b-primary;
    border-radius: 4px;
    font-size: 0.875rem;

    a {
      color: $color-w-primary;
    }
  }

  &[data-popper-placement='top'] .furniture-tooltip-arrow {
    bottom: -16px;
    border-left: $sp-8 solid transparent;
    border-right: $sp-8 solid transparent;

    border-top: $sp-8 solid $color-b-primary;
  }

  &[data-popper-placement='right'] .furniture-tooltip-arrow {
    left: -8px;
    border-top: $sp-8 solid transparent;
    border-bottom: $sp-8 solid transparent;

    border-right: $sp-8 solid $color-b-primary;
  }

  &[data-popper-placement='bottom'] .furniture-tooltip-arrow {
    top: -16px;
    border-left: $sp-8 solid transparent;
    border-right: $sp-8 solid transparent;

    border-bottom: $sp-8 solid $color-b-primary;
  }

  &[data-popper-placement='left'] .furniture-tooltip-arrow {
    right: -8px;
    border-top: $sp-8 solid transparent;
    border-bottom: $sp-8 solid transparent;

    border-right: $sp-8 solid $color-b-primary;
  }

  .furniture-tooltip-arrow {
    height: 16px;
    width: 10px;
  }
}

.translucent-tooltip {
  background: $color-b-secondary;
  opacity: 70%;
  color: $color-w-primary;
  padding: $sp-8;
}

.furniture-tooltip-transition {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1005;

  &.isVisible {
    transition-delay: 0.2s;
    opacity: 100%;
  }
}

.furniture-tooltip-anchor {
  width: fit-content;
}
