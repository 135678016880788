.furniture-app-bar {
  width: 100vw;
  position: fixed;
  background-color: $color-gray-background;
  z-index: 1002;
  top: 0;
  padding: $sp-8 $sp-24 $sp-8 $sp-8;
  display: flex;
  justify-content: space-between;
}

.furniture-app-bar-content {
  display: flex;
  align-items: center;
  height: 48px;
}

.furniture-app-bar-menu {
  padding: $sp-8;
}
