.furniture-header-tabs {
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow-x: auto;
  margin-top: $sp-16;
}

.furniture-header-tab {
  padding: 6px $sp-12;
  color: $color-tide-180;
  font-size: $font-size-sm;
  border-radius: 0.5rem;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;

  &.active {
    background-color: $color-motion-100;
    color: $color-tide-200;
    cursor: default;
  }

  .furniture-header-tab:hover {
    color: $color-tide-200;
  }

  .furniture-header-tab:not(:first-child) {
    margin-left: 8px;
  }
}

@media screen and (min-width: $bp-xl) {
  .furniture-header-tabs .furniture-header-tab {
    font-size: $font-size-m;
    font-weight: $font-semibold;
    padding: $sp-8 $sp-20;
  }
}
