.furniture-link {
  color: $color-deep-cerulean;
  text-decoration: none;

  &:not(.disable-link-style) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &.disable-link-style {
    color: inherit;
    text-decoration: inherit;
  }
}

.furniture-link-nav-active {
  font-weight: bold;
}
