.custom-selectable-list {
  display: flex;
  flex-direction: column;

  .custom-list-item {
    display: flex;
    flex-direction: column;
    margin: $sp-4;
    padding: $sp-12 0;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;

    .custom-list-item-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .custom-list-item-label {
      color: $color-b-secondary !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 10px;
      font-size: 10px;
      font-weight: 600;
      text-align: center;

      div {
        color: inherit !important;
      }
    }

    &.selected {
      background-color: $color-light-cerulean !important;
      .custom-list-item-icon {
        svg g,
        svg path,
        svg circle,
        svg line,
        svg polyline {
          stroke: $color-deep-cerulean !important;
        }
      }

      .custom-list-item-label {
        color: $color-deep-cerulean !important;

        div {
          color: inherit !important;
        }
      }
    }
  }

  .custom-list-item:hover {
    color: $color-b-primary;
    background-color: $color-bg;
    border: none;
  }

  .custom-list-item-shared {
    text-decoration: none;
  }
}

.dropdown-sub-menu-list-item {
  font-size: $sp-14;
  color: $color-tide-200;
  padding: $sp-8;
  margin: 0 $sp-16;
  width: 16vw;
  border-radius: $sp-4;

  &:hover {
    background-color: $color-light-gray;
  }
}
