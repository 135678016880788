.learn-more-anchor {
  cursor: pointer;
  color: $color-motion-200;
  font-size: inherit;
  text-decoration: none;

  &:hover{
    color: $color-black;
    text-decoration: underline;
}
}

.learn-more {
  &.thick {
    a {
      span {
        color: #18669e;
      }
    }
  }
}

