/* These styles are specific to summarize category tables */

.category-table-section {
  margin-top: $sp-24;

  > div + div {
    margin-top: $sp-24;
    @include ultra-narrow(margin-top, $sp-8);
  }

  .category-table-mobile-header {
    display: flex;
    flex-direction: column;
    margin-bottom: $sp-8;
    z-index: 1001;
    background: #fff;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-shadow: hsla(0, 0%, 0%, 0.12) 0px 1px 6px,
      hsla(0, 0%, 0%, 0.12) 0px 1px 4px;

    @supports (position: sticky) {
      position: sticky;
      top: 0;

      &.stuck {
        margin: 0 -24px;
        @include ultra-narrow(margin, 0 -8px);
      }
    }

    /* Special styles to make the sticky header look okay on mobile */
    &.stuck {
      .trendline-legend {
        align-self: baseline;
        min-height: 75px;
        margin: $sp-8;
      }
    }
    .trendline-legend {
      min-height: 64px;
      margin: $sp-8;
    }
    .headline {
      min-height: 56px;
      height: auto;
      align-items: initial;

      .chatter-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.tabs .category-table-section {
  margin-top: 0;
}
