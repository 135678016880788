  .drawer {
    position: fixed;
    bottom: 0;
    right: -100%;
    height: 100vh;
    width: 300px;
    background-color: $color-white;
    border-left: 1px solid $color-tide-120;
    transition: right 0.3s ease-in-out;
    z-index: 1009;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .drawer.open {
    right: 0;
  }
  
  .drawer button {
    margin: $sp-24;
  }
  
  .furniture-drawer-content {
    padding: 20px;
  }

  .furniture-drawer-close-container {
    border-top: 1px solid $color-tide-120;
    padding: $sp-16;
    width: 300px;
  }

  .furniture-drawer-close-button {
    width: max-content;
    min-width: 90px !important;
  }

  .furniture-drawer-title-container {
    display: flex;
    justify-content: space-between;
    padding-left: $sp-12;
    border-bottom: 1px solid $color-tide-120;
  }


  