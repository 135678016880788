.topic-based-assessment-actions {
  padding: $sp-24;
  border-top: $light-grey-border;
}

.topic-based-assessment-button {
  max-width: none !important;
  margin-right: $sp-12;
}

.topic-based-assessment-button + .topic-based-assessment-button {
  margin-left: $sp-12;
}

.topic-based-assessment-button:last-child {
  margin-right: 0;
}
