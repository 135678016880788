.add-survey-button {
  flex: 1 1 auto;
  height: 83px;
  max-width: none;
  margin: 0 $sp-12;
  border: dashed;
  border-radius: 0;
}

.prompt-title {
  display: flex;
  justify-content: space-between;
  height: 69px;
  padding-bottom: 0;
}

.prompt-heading {
  margin-bottom: 2px;
  color: $color-gray-700;
}

.add-custom-survey-item-dialog {
  width: 50%;
}
