.employee-attributes-edit-card-body {
  padding: 0;
}

.employee-attributes-edit-card-row {
  padding: $sp-20 50px 30px $sp-40;
}

.attribute-row-dropdown-container {
  max-width: 400px;
  margin-top: 25px;
}
