.employee-new,
.employee-edit {
  .personal-info {
    font-size: 14px;
    color: $color-b-secondary;
  }

  .fields-row {
    display: flex;

    > div + div {
      margin-left: $sp-16;
    }
  }
}
