.focus-areas-container {
  display: flex;
  justify-content: space-between;

  &.zero-container {
    height: 88px !important;
  }

  .zero-data-state-text {
    padding: $sp-16;
    align-self: center;

    span {
      font-style: italic;
      font-size: 18px;
      color: $color-b-secondary;
    }
  }
  .no-strengths-text {
    color: $color-stack-gray;
  }
}

.focus-areas-strengths-struggles {
  //split this out so we could maintain height of the container without regression in strenghts and struggles
  justify-content: space-evenly;
  height: 142px; // total card height = 198 (142+56 from the header)
}

.focus-areas-most-feedback {
  flex: 1 1 auto;
}
