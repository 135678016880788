.packaging-grid {
  display: flex;
  flex-flow: row wrap;

  > div {
    display: flex;
    flex-basis: calc(50% - 20px);
    justify-content: center;
    flex-direction: column;
    margin: 0 $sp-12 $sp-12 $sp-4;
  }
}

.organization-preferences-body {
  display: flex;
  flex-flow: row wrap;

  .organization-locales-form,
  .organization-substitutions-form {
    @include wide(width, calc(50% - 32px));
    padding: 0 $sp-16 $sp-16;

    > div + div {
      margin-top: $sp-16;
    }
  }
}

.package-selection-submit {
  .violation-text {
    color: $color-redelete;
    display: block;
    padding-top: $sp-12;
  }
}

.package-radio {
  padding-bottom: $sp-12;

  div {
    label {
      white-space: pre;
    }
  }

  > div > div > div > div > svg {
    fill: $color-blue !important;
  }
}

.org-preferences-sub-card-title {
  border-top: $light-grey-border;
  > h3 {
    font-size: 15px;
  }
}

.organization-details-form {
  div {
    margin-right: $sp-20;
    vertical-align: middle;
  }
}

.organization-details-switch-description {
  min-width: 250px;
  margin: 0;
}

.organization-details-form-switch {
  display: flex;
  padding-top: 10px;
}

.package-summary {
  padding-left: $sp-40;

  &.solo {
    padding: 0 0 $sp-16 $sp-16;
  }

  .item {
    padding-right: 14px;
  }
}

.locale-item {
  padding: 0 $sp-8 0 0;
}

.org-detail-employee-attributes-summary {
  color: $color-b-secondary;
  padding: 0 0 $sp-16 $sp-16;
}

.survey-terminology-language {
  font-size: 15px;
  padding: 0 0 $sp-12 $sp-16;
  color: #808080;

  div {
    margin-bottom: $sp-16;
  }

  .substitution-type {
    text-transform: capitalize;
    font-weight: bold;
  }
}

.org-terminology-examples-header {
  max-width: 600px;
}

.org-terminology-examples {
  padding: 0 $sp-20;
  max-width: 600px;

  ul {
    margin-top: 0;
    padding-left: 0;
  }

  li {
    padding-bottom: $sp-8;
  }
}

.org-detail-card {
  position: relative;
}

.retired-org-detail-header {
  background-color: $color-light-shadow;
}

.org-detail-disabled-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.package-dialog-heading {
  padding: 0 $sp-24;

  .package-dialog-description {
    margin-top: 0;
  }

  .package-dialog-form-headings {
    display: flex;
    flex-direction: row;
    font-size: 13px;
    font-weight: 700;

    > div {
      flex: 1 1 50%;
      padding: $sp-16 0;
    }
  }
}

.organization-change-history {
  display: flex;
  padding: 0;

  .header-row {
    padding: 18px;
    border-bottom: 1px solid $color-table-border;

    .header-field {
      flex: 1;
      margin: 0;
      font-size: 13px;
      color: $color-stack-gray;

      .header-span {
        margin: 0;
      }
    }
  }

  .row {
    padding: 18px;
    border-bottom: 1px solid $color-table-border;
  }

  .row-field {
    flex: 1;
    margin: 0;
  }
}

.org-detail-calendar-input {
  width: 500px;
}
