.furniture-toast {
  max-width: none;
  line-height: auto;
  padding: $sp-20;
  height: auto;
  text-align: center;
  color: $color-w-primary;
  background-color: $color-b-secondary;

  &.success {
    background-color: $color-green-infobar !important;
  }

  &.error {
    background-color: $color-redelete !important;
  }
  &.info {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .furniture-toast-details {
    display: inline-flex;
    align-items: center;
  }
}
.custom-toast-button {
  color: $color-w-primary;
}
