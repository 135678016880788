.score-card {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 18px;
    font-weight: 600;

    > svg {
      padding-right: $sp-12;
    }
  }
}

.benchmark-text-compare-campaign {
  color: $color-tide-200;
  font-size: $sp-14;
  margin: $sp-24 0;
}

.engagement-results-title {
  font-weight: 500;
  font-size: 22px;
  margin-left: $sp-24;
}

@media screen and (max-width: 1200px) {
  .sub-card {
    display: flex;
    flex-direction: column;
  }
}

.sub-card {
  display: flex;
}

.score-section {
  display: flex;
  flex-direction: row;
  position: relative;
  @include narrow(flex-direction, column);

  @include wide(flex, 1);
  @include narrow(flex, 1 0);
  @include ultra-narrow(flex, 0 0 auto);
  @include ie11-and-narrow(flex, none);

  .chatter-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.response-rate-section {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  position: relative;
  min-width: 25%;

  @include wide(flex, 1);
  @include narrow(flex, 1 0);
  @include ultra-narrow(flex, 0 0 auto);
  @include ie11-and-narrow(flex, none);
}

.trendline-section {
  flex: 2 0 0%;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top: $light-grey-border;

  @include wide(border-left, $light-grey-border);
  @include wide(min-width, 50%);
  @include narrow(min-width, 100%);
  @include ie11-and-narrow(flex, none);

  // Only apply to IE when wide
  // min-width behaves differently in IE than Chrome
  @media all and (-ms-high-contrast: none) and (min-width: 1200px),
    all and (-ms-high-contrast: none) and (min-width: 900px) and (max-width: 991px) {
    min-width: 0px;
  }
  @media all and (-ms-high-contrast: none) and (max-width: 640px) {
    flex: 2 0 auto;
  }

  #score-trend-chart {
    padding: $sp-24 $sp-16 $sp-8;
  }
}

.hidden-datalabel {
  visibility: hidden;
}

.visible-datalabel {
  visibility: visible;
}

.bell-curve-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-bottom: $sp-12;
  border-top: $light-grey-border;

  align-items: center;
  justify-content: space-between;
  position: relative;

  &.score-card-min-height {
    min-height: 284px;
  }

  .score-meter-title {
    display: flex;
    align-items: center;
    margin-top: $sp-24;
  }

  .engagement-score-title {
    color: $color-b-primary;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .actual-score {
    text-align: center;
    font-weight: $font-semibold;
    padding: $sp-8 0 $sp-8 0;

    .score-value {
      font-size: 26px;
      color: $color-b-primary;
      font-family: 'Cornbread Honey';

      &.score-error {
        font-size: 34px;
      }
    }

    .score-text {
      margin: 0;
      font-family: 'Cornbread Honey';
    }

    .score-max {
      color: $color-b-secondary;
      font-size: 24px;
    }
  }

  .emplify-logo-image {
    height: 24px;
    width: 24px;
  }

  .interpretation {
    text-align: center;
    color: $color-b-secondary;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;

    .bucket-interpretation {
      font-size: 18px;
    }
  }
}
