.furniture-page-title {
  position: relative;
  margin-top: $sp-16;
  overflow: visible;
}

.furniture-page-actions {
  display: flex;
  margin-left: $sp-32;
}

.furniture-page-title-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.rebrand-furniture-page-title-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 96px 0 $sp-32;

  .furniture-page-title {
    display: flex;
    flex-direction: column;
    color: $color-b-primary;
    padding-left: 0;
    max-width: 100%;
    @include ellip;
    overflow: visible;
  }

  .furniture-page-title-info {
    margin-left: $sp-48;
  }

  .furniture-page-title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .furniture-page-actions {
    display: flex;
  }
}

.rebrand-furniture-breadcrumb {
  color: $color-b-primary;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  h1,
  h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: $font-normal;
    margin: 0;
  }

  .furniture-breadcrumb-title {
    color: $color-b-primary;
    font-family: 'Cornbread Honey' !important;
    font-weight: 600;
    font-size: $sp-32;
  }

  .furniture-breadcrumb-title-campaign-list {
    color: $color-b-primary;
    font-weight: 600;
    font-size: $sp-32;
  }

  .furniture-breadcrumb-subtitle {
    color: $color-b-secondary;
    padding-left: $sp-8;
    margin-top: 0;
  }
}

@media screen and (max-width: $bp-sm) {
  .rebrand-furniture-page-title-wrapper {
    margin: $sp-8;
  }
}

@media screen and (min-width: $bp-xl) {
  .rebrand-furniture-page-title-wrapper {
    padding-left: 0;
  }
}
