.action-owner {
  display: flex;
  align-items: center;

  svg {
    margin-right: $sp-8;
    fill: $color-blue;
    height: 20px;
    width: 20px;
  }
}

.action-detail-graphs-pane {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  .action-detail-border {
    display: flex;
    flex-direction: column;
    flex: 1 1 300px;
    margin: $sp-8;
    padding: $sp-8 $sp-16;
    border-radius: 3px;
    border: 2px solid $color-faint-gray;
  }

  .action-detail-score-stats {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;
  }

  .action-detail-score-delta {
    display: flex;
    flex-flow: row;
    align-items: center;

    b {
      padding-right: $sp-4;
    }
  }
}

.action-plan-content {
  padding: 0 $sp-20 15px $sp-20;

  h3 {
    margin-top: $sp-8;
  }

  p {
    max-width: 75%;
  }
}

.action-plan-table-row + .action-plan-table-row {
  // "important" because we are overriding expansion panels
  // default 1px spacing, which is already !important to fight Material UI
  margin-top: $sp-12 !important;
}
