.start-end-calendar-inputs {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $bp-2xl) {
    flex-direction: row;
    gap: $gap-16;
  }
}

.start-end-calendar-input-wrapper {
  min-width: 290px;
  max-width: 375px;

  @media screen and (min-width: $bp-2xl) {
    flex: 1 1 0%;
    max-width: auto;
  }
}
