.analyze-chart {
  position: relative;
  // TODO: Standard way to make tabs flush with card
  // Override margin from content
  margin-top: 0 !important;

  .categories-range-legend {
    display: flex;
    padding: $sp-24 0;
    border-bottom: $light-grey-border;
  }

  .chart-header {
    display: flex;
    flex-direction: column;
    padding: $sp-16 $sp-24 0 $sp-24;
    background: #fff;
    border-radius: 2px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    @include narrow(padding, $sp-16 $sp-12 0 $sp-12);
    @include ultra-narrow(padding, $sp-16 0 0 0);

    .chart-controls {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .left-controls {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      padding: $sp-4;
    }

    .right-controls-container {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    // default styles built for smaller screen sizes. Media query adjusts for larger screen sizes
    // switches positions to row when screen size is big enought
    @media screen and (min-width: $bp-lg) {
      .chart-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .right-controls-container {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }

    // returns toggles and driver resources button to original position in one row once nav turns to mobile nav
    @media screen and (max-width: $bp-md) {
      .right-controls-container {
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }

    .right-controls {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: $sp-4;
    }

    .heatmap-vocab {
      display: flex;
      border-bottom: none;
    }

    .chart-x-labels-container {
      display: flex;
      height: 100px;
      align-items: flex-end;
      padding: 0 20px 0 10px;
    }

    .chart-x-labels {
      display: flex;
      flex: 1;
      overflow: hidden;
      font-size: 14px;
      color: hsl(0, 0%, 25%);
      border-bottom: 1px dashed hsla(0, 0%, 0%, 0.25);
      overflow: visible;
      font-weight: 600;
      cursor: pointer;

      &.straight {
        height: 44px;
        padding-bottom: $sp-12;

        > div {
          justify-content: center;
        }
      }

      &.tilted {
        max-width: 20px;
        white-space: nowrap;
        transform-origin: 45% 50%;
        transform: rotate(300deg) translate(50%, 0);
      }
    }
  }
}

.heatmap-switch-description {
  font-style: 'italic';
  font-weight: 600;
  font-size: 12px;
  color: hsla(0, 0%, 0%, 0.75);
  margin: 3px 16px 0 0;
}

.heatmap-switch {
  width: 180px;
  margin-bottom: 5px;
}

.heatmap-vocab-container {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  justify-content: center;
}
