.one-thing__text {
  font-size: 14px;
}

.action-plan-note-field {
  width: 100%;
}

.action-plan-selected-category-info__text {
  font-size: 16px;
  color: $color-stack-gray;
}

.one-thing-action__text {
  // Font-size is set inline instead of here
  // because we are fighting the font-size of the Text body / Text info
  font-weight: 700;
}

.one-thing-action-row {
  align-items: center;
  min-height: 90px;
  padding: 0 $sp-8;
}

.one-thing-action-row-centered {
  align-items: center;
  justify-content: center;
  min-height: 90px;
  padding: 0 $sp-8;
}

.one-thing-action-description {
  padding: $sp-16 0;
  min-width: 215px;
}

.no-margin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hidden {
  display: none;
}

.expand-button {
  margin: $sp-8 0;
}
