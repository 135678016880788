// TODO: rely on SelectField's width settings
.role-picker {
  width: 30%;
}

.permissions-selectors {
  margin: $sp-24;
}

// TODO: Use utility classes instead
.group-access-dropdown-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.group-access-segment-label {
  font-weight: 700;
  color: $color-stack-gray;
  margin-bottom: 10px;
}

.access-level-warning {
  font-weight: 700;
  margin: 14px 0;
}

.permissions-radio-button-label {
  font-size: 18px;
  font-weight: $font-semibold;
}

.manage-coaching-switch-description {
  margin: 0;
  font-weight: bold;
}
