// Topaz Button Hex Codes
$color-deep-cerulean: #2d536c;
$color-light-cerulean: #d6eaf6;
$color-methodology-amber: #f1bf30;
$color-success-emerald: #89c445;
$color-destructive-ruby: #c23934;
$color-disabled-gray: #dcdcdc;
$color-white: #ffffff;

// Topaz Badge Hex Codes
$color-darker-amber: #91731d;
$color-success-light-emerald: #e2f0d1;
$color-darker-green: #4b6c26;
$color-destructive-light-ruby: #f0cecc;
$color-darker-ruby: #922b27;
$color-background-amber: #f9e5ac;

// tints
$color-emerald-tint-7: #dbedc7;
$color-emerald-800: #527629;
$color-onyx-tint-8: #cccccc;
$color-onyx-tint-9: #eaeaea;
$color-onyx-tint-10: #f8f8f8;
$color-turqoise-tint-7: #b3e4e4;
$color-ruby-tint-7: #ecc3c2;
$color-ruby-600: #af332f;
$color-pearl: #f6f2eb;
$color-methodology-tint-7: #faebc1;
$color-amber-50: #fefcf5;
$color-amber-400: #f5d26e;
