.benchmark-text-container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.benchmark-text-compare-industry {
  color: $color-tide-170;
  font-size: $sp-12;
  margin: 0 $sp-36;
  text-align: center;
}

.benchmark-text-percentile {
  color: $color-tide-200;
  font-size: $sp-14;
  font-weight: 500;
  margin: $sp-4 0;
}

.benchmark-tooltip-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;

  .title-text {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: $sp-12;
  }

  .benchmark-score-section {
    display: flex;
    justify-content: space-between;
    padding: 0 $sp-8;

    .score {
      font-weight: 600;
    }
  }

  .benchmark-bar-container {
    height: 48px;

    .benchmark-tick-line {
      stroke: $color-b-primary;
      stroke-width: 6px;
      stroke-linecap: round;
    }

    .benchmark-tooltip-bar {
      &#full-score-bar {
        fill: $color-faint-gray;
      }

      &#score-bracket-1,
      &#score-bracket-6 {
        fill: $color-worst;
      }
      &#score-bracket-2,
      &#score-bracket-7 {
        fill: $color-bad;
      }
      &#score-bracket-3,
      &#score-bracket-8 {
        fill: $color-neutral;
      }
      &#score-bracket-4,
      &#score-bracket-9 {
        fill: $color-good;
      }
      &#score-bracket-5,
      &#score-bracket-10 {
        fill: $color-best;
      }
    }
  }
}
