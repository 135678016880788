.protip-content {
  width: 100%;
  height: fit-content;
  padding: $sp-8;
  background-color: $color-kin-200;
  border-radius: $sp-8;
  color: $color-tide-200;
  display: flex;
  justify-content: space-between;

  p {
    line-height: $sp-24;
  }

  .protip-title {
    font-size: $sp-16;
    margin: 0 0 $sp-16 0;

    font-weight: 600;
  }

  ul {
    line-height: $sp-24;
    padding-left: $sp-24;
  }

  li {
    font-size: $sp-16;
  }

  .protip-list-item-button {
    color: $color-motion-200;
    cursor: pointer;
  }
}
