.campaign-status-label {
  text-transform: capitalize;
  text-transform: uppercase;

  &.active {
    color: #aed581;
  }

  &.inprogress {
    color: #aed581;
  }

  &.errored {
    color: $color-redelete;
  }
}
