.category-table {
  display: flex;
  flex: 1 0 0%;
  overflow-x: auto;

  .header-field {
    min-width: 0;
    align-items: center;
    font-weight: $font-semibold;
  }

  .header-row {
    font-size: 15px;
    user-select: none;
  }

  .selectable-row {
    min-height: $sp-36;

    &.adjacent {
      border-top-style: dashed;
    }
  }

  .score-field {
    margin-left: $sp-16;
  }

  .mobile-trendline-axis {
    display: flex;
    flex: 1 0 0%;
    height: $sp-36;
    min-height: $sp-36;
    align-items: center;
    justify-content: space-between;
    margin: 0 $sp-16;

    span {
      font-size: $sp-14;
      color: #808080;
    }

    /* This overrides span styling because of css order */
    .skeleton {
      color: transparent;
    }
  }
}

.driver-detail-link {
  color: $color-b-primary;
  text-decoration: none;
}

.table-category-prompt {
  display: inline-block;
  padding: 6px;
  background-color: $color-motion-90;
  color: $color-tide-200;
  border-radius: $sp-4;
  font-weight: $font-normal;
  font-size: $sp-14;
  margin: 10px 0;
  line-height: $sp-20;
  border: 1px solid $color-motion-100;
}
