.no-results-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.no-results-list-item-header {
  font-weight: $font-semibold;
}

.no-results-content {
  font-weight: $font-normal;
  font-size: 16px;
  line-height: 24px;
  color: $color-tide-170;
  margin: $sp-16;

  h3 {
    margin-top: 0;
  }
}

.no-results-header {
  font-weight: $font-semibold;
  font-size: 22px;
  line-height: 28px;
}

.no-results-learn-more a {
  color: $color-motion-200;
  text-decoration: none;
}

.no-results-learn-more a:hover {
  color: $color-tide-200;
  text-decoration: underline;
}

.no-results-image {
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}
