.group-emplify-score-context {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.54);
  }

  b {
    font-size: 20px;
  }
}

.interpretation-content {
  text-align: center;
}

.bucket-interpretation {
  font-weight: bold;
  width: fit-content;
  margin: $sp-24 auto 0;
  padding: $sp-8 $sp-16;
  border-radius: $sp-2;

  &.bracket-1, &.bracket-6 {
    color: $color-white;
    background-color: $color-worst;
  }
  &.bracket-2, &.bracket-7 {
    color: $color-white;
    background-color: $color-bad;
  }
  &.bracket-3, &.bracket-8 {
    color: $color-tide-200;
    background-color: $color-neutral;
  }
  &.bracket-4, &.bracket-9 {
    color: $color-tide-200;
    background-color: $color-good;
  }
  &.bracket-5, &.bracket-10 {
    color: $color-white;
    background-color: $color-best;
  }
  &.bracket-not-available {
    background-color: $color-disabled;
  }
}

.bucket-decoration-bar {
  position: absolute;

  &.on-top {
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;

    @include wide(border-radius, 0 0 0 2px);
  }

  &.on-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;

    @include wide(border-radius, 0 0 0 2px);
  }

  &.on-left {
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    height: 100%;

    border-radius: 2px 0 0 2px;
  }

  &.bracket-1,
  &.bracket-6 {
    background-color: $color-worst;
  }
  &.bracket-2,
  &.bracket-7 {
    background-color: $color-bad;
  }
  &.bracket-3,
  &.bracket-8 {
    background-color: $color-neutral;
  }
  &.bracket-4,
  &.bracket-9 {
    background-color: $color-good;
  }
  &.bracket-5,
  &.bracket-10 {
    background-color: $color-best;
  }
  &.bracket-not-available {
    background-color: $color-disabled;
  }
}

.score-change-component {
  display: flex;
  align-items: center;
}

.qualifier {
  margin-right: $sp-4;
  font-size: 24px;
  font-weight: 900;

  &.up {
    color: $color-green-infobar;
  }

  &.down {
    color: $color-redelete;
  }
}

.score-change-text-up {
  color: $color-green-infobar;
}

.score-change-text-down {
  color: $color-redelete;
}

.engagement-score-value {
  font-size: $sp-48;
  font-weight: $font-semibold;
  color: $color-tide-200;
  font-family: 'Cornbread Honey';
  margin-top: $sp-32;
}
