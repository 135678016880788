.open-response-text {
  white-space: pre-wrap;
  line-height: 18px;
}

.zero-responses-text {
  padding: $sp-24;
  font-style: italic;
  font-size: 18px;
}

.chips-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &.chips {
    margin: $sp-8;
  }

  .polarity-color-positive {
    background-color: $color-emerald-tint-7;
  }
  .polarity-color-negative {
    background-color: $color-ruby-tint-7;
  }

  .polarity {
    font-weight: 700;
    height: 16px;
    min-width: 48px;
    justify-content: center;
  }

  .related-category {
    background-color: $color-onyx-tint-10;
    font-weight: 700;
    height: 16px;
    min-width: 48px;
    justify-content: center;
  }

  .filterable-hidden {
    background-color: $color-onyx-tint-10;
    color: $color-b-secondary;
    height: 16px;
    min-width: 48px;
    padding: $sp-16;
    justify-content: center;

    path,
    line {
      stroke: $color-b-secondary;
    }
  }

  .filterable {
    background-color: $color-light-cerulean;
    color: $color-deep-cerulean;
    height: 16px;
    min-width: 48px;
    padding: $sp-16;
    justify-content: center;

    path,
    line {
      stroke: $color-deep-cerulean;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    padding: 0 !important;
    pointer-events: none;
  }
}
