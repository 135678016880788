.furniture-filter-chips-bar-divider {
  height: 1px;
  background-color: $color-gray-background;
}

.furniture-filter-chips-bar {
  padding: 0 $sp-16;
}

.furniture-filter-chips-bar-chips {
  gap: $sp-8;

  .tpz-chip {
    margin: 0;
  }
}

.furniture-filter-chips-bar-clear-btn {
  min-width: auto;
  white-space: nowrap;
}
