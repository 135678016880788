.organizations-heading {
  display: flex;
  padding: 5px;
}
.organizations-search-icon {
  padding-left: 5px;
  margin-right: 10px;
  align-self: center;
}

.organizations-table {
  td {
    max-width: 300px;
  }
}
