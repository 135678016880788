.tpz-btn-file-input {
  width: 100%;
  z-index: 1000 !important;
}

.confirm-upload-dialog {
  z-index: 2001;
}

.employee-management-switch {
  padding-top: 8px;
}
