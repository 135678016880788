.furniture-filter-menu {
  margin-top: $sp-12;
  padding: $sp-24;
  border-radius: $sp-8;
  background-color: $color-white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
}

.furniture-filter-menu-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.furniture-filter-menu-field {
  width: 254px;
}

.furniture-filter-menu-field-value-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
