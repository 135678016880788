.results-page-title {
  flex-direction: column !important;
  align-items: flex-start !important;

  .results-filter-button-bar {
    margin-left: 0;
    div:first-child {
      margin-left: 0;
    }
  }
}
