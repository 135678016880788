.furniture-card {
  background-color: $color-w-primary;
  box-shadow: 0px 1px 8px 3px $color-drop-shadow;
  border-radius: 4px;
  margin-top: $sp-16;

  &.tabs {
    margin-top: 0 !important;
    border-radius: 0 $sp-2 $sp-2 $sp-2;
  }
}

.furniture-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 $sp-16;
  font-size: 18px;
  max-height: 100%;

  &.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      flex: 1;
      display: flex;
      align-items: center;

      img,
      svg {
        /* The left padding we need comes from furniture-card-header. */
        margin: $sp-16 $sp-16 $sp-16 0;
      }
    }
  }

  h3 {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0; /* not necessary because min-height */
    font-size: 18px;
  }

  &.divider {
    border-bottom: $light-grey-border;
  }
}

.subline {
  padding: 0 $sp-16;
  font-size: 14px;
  color: $color-stack-gray;
}

.furniture-card-body {
  padding: $sp-16;

  &.no-content {
    color: $color-b-secondary;
    font-style: italic;
    line-height: 1.2;
    flex: 1 0 auto;
  }

  &.animated {
    transition: max-height 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
      opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    overflow: hidden;
    opacity: 1;

    &.closed {
      padding: 0 $sp-16;
      opacity: 0;
    }
  }
}

.furniture-card-actions {
  padding: $sp-16;
}

.furniture-card-row {
  padding: $sp-16;

  &.divider {
    border-bottom: $light-grey-border;
  }
}

.furniture-card-expansion {
  position: relative;
  padding-right: $sp-32;

  &.divider {
    border-bottom: $light-grey-border;
  }
}

.furniture-card-section {
  &.padding {
    padding: $sp-16;
  }

  &.divider {
    border-bottom: $light-grey-border;
  }
}

hr {
  color: $color-b-12;
}

.furniture-card-header-subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  margin-top: $sp-8;
}
