.download-table-card-header {
  padding: $sp-16 $sp-24;
}

.downloads-table-header-row {
  display: flex;
  align-items: center;
  padding: $sp-8 $sp-16;
  font-size: 12px;
  background-color: $color-gray-background;

  .header-field {
    display: flex;
    align-items: center;
  }
}

.downloads-table-row {
  display: flex;
  align-items: center;
  padding: $sp-16;
  border-bottom: 1px solid $color-faint-gray;
  background-color: $color-w-primary;
}
