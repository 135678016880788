.statcard--card {
  display: flex;
  flex-wrap: wrap;
}

.statcard--box {
  flex: 1 1 0%;
  min-height: 300px;
  min-width: 225px;
  height: 100%;
  padding: $sp-16;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-right: $light-grey-border;

  border-top: $light-grey-border;
}

.statcard--number {
  font-size: 48px;
  font-weight: 700;
}

.statcard--label {
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
  width: 100%;
}
