.app-bar-action {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: $sp-16;
  line-height: normal !important;

  @media screen and (min-width: $bp-xs) {
    width: auto;
    margin: 0 $sp-12;
    padding-bottom: 0;
  }
}

.employee-edit-title {
  padding-right: $sp-8;
  font-weight: 600;
}

.employee-contact {
  white-space: normal;
  line-height: 1.6;
  text-overflow: ellipsis;
  overflow: hidden;
}
