.f-row {
  display: flex;
  flex-direction: row;
}

.distribution-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 $sp-16;
  @include ultra-narrow(padding, 0 $sp-36 $sp-24);

  .circle {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: $sp-4;
    margin-left: $sp-4;

    &.highly-disengaged,
    &.invest {
      background-color: $color-worst;
    }
    &.disengaged,
    &.develop {
      background-color: $color-bad;
    }
    &.moderately-engaged,
    &.encourage {
      background-color: $color-neutral;
    }
    &.highly-engaged,
    &.recognize {
      background-color: $color-good;
    }
    &.extremely-engaged,
    &.celebrate {
      background-color: $color-best;
    }
    &.did-not-respond {
      background-color: #e7e7eb;
    }
    &.turnover {
      background-color: #000000;
    }
    &.new-hires {
      background-color: #4a90e2;
    }
    &.obscured {
      @include faint-striping;
    }
  }

  .labels {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
    font-size: 12px;
    color: $color-b-secondary;
    margin: 0 12%;
    @include ultra-narrow(margin, 0 $sp-12);
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: $sp-8;
    }

    span {
      line-height: 16px;
      font-weight: 600;
      &.value {
        font-weight: normal;
      }
    }
  }
}

.component-legend {
  @extend .f-row;
  flex: 1;

  .bar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex: 1;

    @include wide(justify-content, center);

    .bar-row {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $sp-8;
    }
  }

  .bar {
    .circle {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-right: $sp-16;
      @include ultra-narrow(display, none);

      &.highly-disengaged {
        background-color: $color-worst;
      }
      &.disengaged {
        background-color: $color-bad;
      }
      &.moderately-engaged {
        background-color: $color-neutral;
      }
      &.highly-engaged {
        background-color: $color-good;
      }
      &.extremely-engaged {
        background-color: $color-best;
      }
    }

    span {
      font-size: 13px;
      color: $color-b-secondary;
      padding: 0;
      font-weight: 700;

      &.value {
        font-weight: normal;
      }

      &.highly-disengaged {
        color: $color-worst;
      }
      &.disengaged {
        color: $color-bad;
      }
      &.moderately-engaged {
        color: $color-neutral;
      }
      &.highly-engaged {
        color: $color-good;
      }
      &.extremely-engaged {
        color: $color-best;
      }
    }
  }
}
