.group-member-list-heading {
  display: flex;
}

.group-campaign-members-card {
  // Override the default card margin set in
  // src/containers/structure_app/styles.scss
  margin: 0 !important;
}

.group-member-list-campaign-date {
  padding-left: 5px;
}

.group-member-list-search-field-container {
  display: flex;
  justify-content: flex-end;
}

.group-member-list-card-body {
  // Override default card body padding
  // We want the contained smart table to take up the full card
  padding: 0 !important;
}

.group-member-list-search-field {
  // Override default search field width
  width: 230px !important;
}
