.chatter-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 0;
  font-size: initial;

  .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;

    .headline-icon {
      padding: 0 $sp-16;
    }

    .spacer-icon {
      padding: $sp-12 $sp-8;
    }

    .chatter-icon {
      position: relative;
    }
  }

  .chatter {
    background: $color-info-bg;
    padding: 0 $sp-16;
    border-left: 8px solid $color-deep-cerulean;
    opacity: 1;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:not(.open) {
      height: 0px !important;
      opacity: 0;
      pointer-events: none; /* This prevents the chatter from blocking category headers */
    }

    p {
      line-height: 20px;
    }
  }
}

.chatter-icon {
  svg {
    color: $color-b-secondary !important;
  }

  &.open {
    background-color: $color-deep-cerulean;

    svg {
      color: white !important;
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
    }

    &.bottom:after {
      left: 20px;
      bottom: -8px;
      border-top: 8px solid $color-deep-cerulean;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &.right:after {
      top: 20px;
      right: -8px;
      border-left: 8px solid $color-deep-cerulean;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      @include narrow(display, none);
    }
  }
}
