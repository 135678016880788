.campaign-detail:first-of-type {
  h2 {
    margin-top: 0;
  }
}

.campaign-detail {
  .campaign-title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    h3 {
      margin: 0 $sp-12 0 0;
      display: inline;
      vertical-align: middle;
    }
  }
}

.campaign-attributes,
.campaign-expansion-summary {
  font-size: 15px;
  line-height: 1.618;
  display: flex;
  flex-direction: column;

  div {
    @include narrow(width, 100%);
  }
}

.campaign-expansion-summary {
  padding: $sp-16;
}

.campaign-expansion-summary.empty {
  padding: 0;
  padding-bottom: $sp-16;
}

.expansion-panel-actions {
  .note-worthy-events-submit:not([disabled]) {
    color: $color-blue !important;
  }
}

.message-table {
  margin-top: 10px;

  .skeleton {
    height: 250px;
  }

  .header-row {
    padding: $sp-16 $sp-12;
    background-color: $color-faint-gray;
  }

  .header-field {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
  }

  .row {
    padding: $sp-20 $sp-12;
    border-bottom: $light-grey-border;
  }

  .row:last-child {
    border-bottom: none;
  }

  .row-field {
    flex: 1;
  }
}
