.empty-state-card {
  display: flex;
  flex-direction: column;
  background: #eff6fc !important;

  @media screen and (min-width: $bp-md) {
    flex-direction: row;
  }

  .body-text {
    line-height: 28px;
  }

  .empty-state-btn {
    max-width: 200px !important;
  }

  .resources {
    background: $color-w-primary !important;

    .resource-btn {
      justify-content: start !important;
    }

    .resource-btn span {
      white-space: normal;
    }

    .resource-btn svg {
      flex-shrink: 0;
    }

    .separator {
      border: 1px solid $color-b-12;
    }
  }
}

.empty-state-card-heading-wrapper {
  display: flex;
  flex-direction: column;
  flex: 3 3 0%;
  gap: $gap-24;
  padding: $sp-24;
}

.empty-state-card-heading-content {
  display: flex;
  flex-direction: column;
  gap: $gap-24;
}

.empty-state-card-resources {
  display: flex;
  flex-direction: column;
  flex: 2 2 0%;
  padding: $sp-24;
  font-size: 20px;
  font-weight: bold;
}

.empty-state-card-image-wrapper {
  display: none;

  @media screen and (min-width: $bp-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.empty-state-card-image {
  height: 100%;
  width: auto;
  max-width: 100%;

  @media screen and (min-width: $bp-md) {
    max-width: 300px;
  }
}
