.job-detail {
  display: flex;

  .info {
    flex: 20%;
  }

  .logs {
    flex: 80%;
  }
}

.log-row {
  height: 20px;
  font-size: 14px;
  cursor: pointer;
}

.log-details {
  width: 100%;
  overflow: null;
  padding-left: 50px;
}
