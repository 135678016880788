.driver-detail-row {
  // overriding existing class
  .header-row {
    display: flex;
    font-size: 15px;
    align-items: center;
    user-select: none;
    border-bottom: $light-grey-border;
  }

  // overriding existing class
  .header-field {
    min-width: 0;
    align-items: center;
    font-weight: 600;
  }

  // overriding existing class
  .d3-summary-influence {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.driver-detail-header-comments-count {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  color: $color-stack-gray;
}
