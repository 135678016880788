@import './app_bar/styles';
@import './badge/styles';
@import './buttons/styles';
@import './card/styles';
@import './chip/styles';
@import './date_time_picker/styles';
@import './dialog/styles';
@import './drawer/styles';
@import './expansion_panel/styles';
@import './filter_chips_bar/styles';
@import './filter_menu/styles';
@import './info_bar/styles';
@import './layout/styles';
@import './link/styles';
@import './menu/styles';
@import './page_subtitle/styles';
@import './page_title/styles';
@import './popover/styles';
@import './progress/styles';
@import './table/styles';
@import './tabs/styles';
@import './toast/styles';
@import './tooltip/styles';
@import './typography/styles';
@import './checkbox/styles';
@import './text_field/styles';
@import './select_field/styles';

.furniture-change-field {
  display: flex;
  flex: 1 0 0%;

  .percent {
    display: flex;
    flex: 1 0 0%;
    align-items: center;
    padding-left: $sp-16;
  }

  &.is-vertical {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 $sp-16;

    > div {
      flex: 1;
    }
  }
}
