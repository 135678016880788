.d3-heatmap {
  position: relative;
  margin: 0 $sp-24;

  @include narrow(margin, 0 $sp-12);
  @include ultra-narrow(margin, 0);

  .d3-heatmap-y-axis-label-link {
    fill: $color-deep-cerulean;
    text-decoration: underline;
    cursor: pointer;
  }

  .d3-heatmap-y-axis-label-link:hover {
    font-weight: 600;
  }
}

.d3-heatmap-cell {
  stroke: $color-w-primary;
  stroke-width: 2;
}

.d3-heatmap-influence-cell-outer-circle {
  stroke: #d0d0d0;
  fill: transparent;
  stroke-dasharray: 5 3;
}

.d3-heatmap-cell-value-label {
  text-anchor: middle;
  font-weight: 700;
  font-size: 14px;
}

.d3-axis {
  display: flex;
  flex: 1;
  font-weight: 500;
  margin: 0;
}
