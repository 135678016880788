header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1004;
  margin-bottom: $sp-48;

  .menu-button {
    display: none;
    text-align: center;
    padding-top: 14px;
  }
  @media screen and (max-width: $bp-xl) {
    .menu-button {
      display: inherit;
    }
  }

  @include drawer-closed(margin-left, 0);
  transition: all 0.25s ease;

  .menu-button {
    margin-top: 15px !important;
  }
}

.app-bar-icon {
  svg {
    color: $color-b-primary;
  }
}

.app-header {
  header {
    padding: $sp-48 0 $sp-8 0;
    z-index: 1;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .menu-button {
    display: none;
    text-align: center;
  }
  @media screen and (max-width: $bp-xl) {
    .menu-button {
      display: inherit;
    }
  }

  .page-header {
    margin-top: $sp-48;
  }

  .app-bar-settings-dropdown {
    max-height: 85vh;
    overflow-y: auto;
  }
}

.profile-button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.app-bar-settings {
  padding: $sp-8;
  border: none !important;
  background-color: transparent !important;
  border-radius: $sp-8 !important;

  &:hover {
    background-color: $color-light-gray !important;
  }

  .app-bar-settings-icon {
    font-size: 22px;
  }
}
