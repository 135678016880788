.distribution-breakdown {
  display: flex;
  align-items: center;
  width: 100%;
}

.distribution-breakdown-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 75px;
}

.distribution-breakdown-labels {
  display: flex;
  height: 13px;

  .label {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 1px;
    text-align: center;
  }
}

.distribution-breakdown-bars {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  height: 24px;

  .bar {
    text-align: center;

    &.highly-disengaged,
    &.invest {
      background-color: $color-worst;
    }
    &.disengaged,
    &.develop {
      background-color: $color-bad;
    }
    &.moderately-engaged,
    &.encourage {
      background-color: $color-neutral;
    }
    &.highly-engaged,
    &.recognize {
      background-color: $color-good;
    }
    &.extremely-engaged,
    &.celebrate {
      background-color: $color-best;
    }
    &.did-not-respond {
      background-color: #e7e7eb;
    }
    &.turnover {
      background-color: #000000;
    }
    &.new-hires {
      background-color: #4a90e2;
    }
    &.obscured {
      @include faint-striping;
    }
    &.blank {
      flex: 1;
      background: #dfdbdb;
      line-height: 24px;
    }
  }
}

.distribution-breakdown-spacer {
  height: 13px;
}

.distribution-breakdown-count {
  display: flex;
  flex-direction: column;
  padding-left: $sp-2;
  font-weight: 600;
  height: 24px;
  align-items: flex-start;
  justify-content: center;
  min-width: 34px;

  span {
    font-size: 14px;
  }
}
