.employer-id-switch {
  width: 250px;
  padding-top: $sp-12;
}

.cycles-per-year-picker {
  padding-left: 10px;
}

.dropdown-label {
  float: 'left';
  margin-top: $sp-24;
  margin-right: 10px;
  font-weight: bold;
}

.new-coaching-session-ttf {
  max-width: 600px;
}

.package-type-label {
  padding: $sp-16 0 $sp-8 0;
}
