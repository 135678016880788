.campaign-history {
  h3 {
    display: inline-block;
  }

  .button-container {
    float: right;
    margin: 10px 0;
  }
}

.campaign-controls {
  text-align: right;
}

.campaign-history-refresh {
  position: absolute;
  top: 48px;
  right: 25px;
}
