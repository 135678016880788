#participation-sparkline-highcharts {
  padding: $sp-24 $sp-24 $sp-24 $sp-4;
  display: flex;
  flex: 1 0 0%;
  width: 100%;
  // Make sure the chart takes up the 100% of the width of it's parent
  // https://github.com/highcharts/highcharts/issues/1157
  .highcharts-container,
  .highcharts-container svg {
    width: 100% !important;
  }
  table {
    table-layout: fixed;
  }
}

.participation-sparkline-container {
  display: flex;
  flex: 1 0 0%;
  flex-direction: row;
  width: 100%;
  // Apply a height for loading on narrow
  @include narrow(height, 122px);
  @include ie11-and-narrow(flex, none);
}

.sparkline-xAxis-plotline-label {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 22px 0 22px $sp-16;
  font-size: 14px;

  span {
    color: #ccc;
  }
}
