.page-status-card-wrapper {
  display: flex;
  flex-direction: row;
  padding: $sp-8 $sp-24;
  line-height: 1.2em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .icon {
    flex: 1;
    align-self: center;
    display: flex;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 100px 0;
    max-width: 200px;
    min-width: 180px;
  }

  .explanation {
    flex: 2;
    align-self: center;
    margin-left: $sp-24;
    margin-bottom: $sp-16;
  }
}
