// We will have webpack build a single global CSS file.
// However, we can have as many .scss files as we want.
// Just make sure to import all other SCSS files into this one.
// That way, when we require this file in the root component, webpack
// will crawl through all of the imports to find and bundle every SCSS
// file that we use.

$local-font-path: '../static/' !default;
$phantom-sans-variants: (
  'PhantomSans-Regular': (
    font-weight: 400,
  ),
  'PhantomSans-Italic': (
    font-weight: 400,
    font-style: italic,
  ),
  'PhantomSans-Medium': (
    // Phantom Sans Medium is 500, but set to 600 for backward compatibility
    // with Proxima Nova - the previously used font face.
    font-weight: 600,
  ),
  'PhantomSans-MediumItalic': (
    font-weight: 600,
    font-style: italic,
  ),
);

* {
  font-family: 'Phantom Sans';
  box-sizing: inherit;
}
*:before,
*:after {
  border-width: 0;
  border-style: solid;
  border-color: theme('colors.tide.120');
}

html {
  box-sizing: border-box;
}

body {
  background-color: $color-gray-background;
  margin: 0;
  padding: 0;
  // This is to prevent resizing of text on iOS Safari
  -webkit-text-size-adjust: 100%;
}

a {
  color: $color-action-secondary-500;
}

a[href=''] {
  text-decoration: none;
  pointer-events: none;
}

input[type='text']::-ms-clear {
  display: none;
}

.clearfix:before,
.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}

// Local Dev Styles

.react-hot-loader-error-overlay {
  > div {
    z-index: 10000;
  }
}

// Import fonts

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-LightItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-RegularItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Semibold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-SemiboldItalic.otf')
    format('opentype');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-BoldIt.otf') format('opentype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Extrabold.otf') format('opentype');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Proxima Nova;
  src: url('../static/proxima/ProximaNova-Black.otf') format('opentype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Cornbread Honey;
  src: url('../static/cornbread/CornbreadHoney-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@each $variant, $declarations in $phantom-sans-variants {
  @font-face {
    font-family: Phantom Sans;
    src: url('#{$local-font-path}/phantomSans/#{$variant}.woff2')
    format('woff2'),
    url('#{$local-font-path}/phantomSans/#{$variant}.woff') format('woff');
    @each $property, $value in $declarations {
      #{$property}: $value;
    }
  }
}
