// These styles are taken from welcome
// TODO: Find a way to share these styles appropriately between rows that use them
.emplify-score-row {
  position: relative;
  display: flex;
  border-top: $light-grey-border;
  color: $color-b-primary;

  // min-height: 90px; // Category score row uses min height
  position: relative; // Allow decoration bar to be positioned relative to the row

  // Overlap with engagement-by-group-table

  .row-field {
    font-size: 18px;
    height: 100%;
    align-items: center;

    &.name-field {
      padding: 0 $sp-16;
      @include narrow(padding, 0);
      overflow: hidden;
    }

    &.response-breakdown-field {
      .distribution-breakdown {
        padding: 0 $sp-8 0 $sp-16;
        @include narrow(padding-right, $sp-2);
      }
    }

    &.score-field {
      span {
        span {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }

    &.spacer-field {
      width: 48px;
    }
  }
}

.emplify-score-row {
  .percent {
    padding: 0 !important;
  }
}

.segmented-results-switch-description {
  font-style: 'italic';
  font-weight: 600;
  font-size: '12px';
  color: hsla(0, 0%, 0%, 0.75);
  margin: 0;
}

.segmented-results-switch {
  width: 180px;
}
