.v3-engagement-trend-section {
  display: flex;
  flex: 2 2 0%;
  flex-direction: column;
  min-width: 50%;
  border-top: $light-grey-border;

  @include narrow(border-top, $light-grey-border);
  @include wide(flex, 2);
  @include narrow(flex, 2 0);
  @include ultra-narrow(flex, 0 0 auto);
  @include ie11-and-narrow(flex, none);

  .v3-engagement-breakdown-header {
    /* We need to override default padding */
    padding: 0 0 0 $sp-16;

    h3 {
      flex: 4;
    }

    .the-chart {
      display: flex;
    }
  }

  .v3-distribution-tabs {
    flex: 1;
  }
}
