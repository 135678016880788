.furniture-popover-anchor {
  z-index: 999;
}

.furniture-popover-wrapper {
  z-index: 1006;
}
.furniture-popover {
  background-color: $color-white;
  width: 100%;
  box-shadow: 0 1px 8px 3px $color-faint-gray;
}

.furniture-popover-transition {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &.isVisible {
    opacity: 100%;
  }
}
