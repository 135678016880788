.d3-heatmap-legend {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  color: $color-b-75;

  .legend-title {
    margin-bottom: 10px;
    text-align: center;
  }

  .swatch-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;

    .range-legend {
      display: flex;
      flex: 3;
      flex-direction: column;
    }

    .anchor {
      flex: 2;

      &.left {
        text-align: right;
        padding-right: $sp-12;
      }

      &.right {
        text-align: left;
        padding-left: $sp-12;
      }
    }

    .legend-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .swatch.heatmap {
        min-height: 12px;
      }
    }
  }

  .legend-markers {
    display: flex;
    justify-content: center;

    > div {
      margin-top: $sp-4;
      width: 38px;
      text-align: center;
      font-style: normal;
    }

    .legend-markers-zero {
      width: 76px;
    }
  }
}

.obscured-legend {
  display: flex;
  flex: 1;
  align-items: center;

  .obscured-label {
    margin-left: $sp-8;
  }
}

.no-data-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $sp-16;
  font-size: 12px;
  font-style: italic;
  font-weight: $font-semibold;
  color: $color-b-75;
}
.dotted-line {
  border-top: 3px dashed $color-dark-gray;
  width: 20px;
}
