.glass-pane {
  position: fixed;
  background-color: $color-b-secondary;
  height: 100%;
  width: 100%;
  z-index: 1005;
}

//update when engage-rebrand is live.
.rebrand-app .glass-pane {
  background-color: transparent;
}
@media screen and (min-width: $bp-xl) {
  .glass-pane {
    display: none;
  }
}
