.furniture-progress-bar-container {
  width: 100%;
  box-sizing: border-box;

  .furniture-progress-bar-title {
    display: flex;
    justify-content: space-between;

    h4,
    span {
      margin: 0 0 $sp-8 0;
    }
  }

  .furniture-progress-bar {
    box-sizing: border-box;
    background-color: $color-gray-background;
    height: 8px;
    border-radius: 0.25rem;
    width: 100%;
    margin-top: 10px;
  }

  .furniture-progress {
    background-color: $color-basic-blue;
    height: 100%;
    border-radius: 0.25rem;
  }
}

.furniture-circular-icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: $sp-16;
}

.furniture-circular-button-icon {
  width: fit-content;
}
