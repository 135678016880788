.zero-actions-card {
  margin-top: $sp-12;
  padding: 10px;

  .action-planning-empty-state-content {
    display: flex;
    align-items: center;
    @include ultra-narrow(flex-direction, column);
  }

  .action-planning-empty-state-spacer {
    width: 40px;
    height: 20px; // In column mode, we don't need as much breathing room
  }

  .action-planning-empty-state-illustration {
    height: 150px;
  }
}

.action-occurrences-section {
  padding-bottom: $sp-12;

  h2 {
    margin: 0 $sp-12 0 0;
  }

  .furniture-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.ready-to-get-started-message {
  padding: $sp-16;

  > div {
    display: flex;
    align-items: center;
    padding: $sp-16 0;

    img {
      height: 30px;
      width: 30px;
      padding: 0 $sp-16 0 $sp-8;
    }
  }
}

.no-actions-tooltip-container {
  display: inline-block;
  height: 40px;
  line-height: 40px;

  .tooltip {
    padding-bottom: 5px;
  }
}

.no-actions-tooltip {
  background-color: $color-darkest-blue;
  color: $color-w-primary;
  font-size: 18px;
  line-height: 1.3;
  max-width: 350px;
  min-width: 250px;
  border-radius: 2px;
  padding: $sp-8 $sp-16;

  .no-actions-tooltip-header {
    display: block;
    padding: $sp-12 0;
    font-weight: bold;
  }
}

.action-dashboard-nav {
  align-items: center;
  margin-top: 0;
  margin-bottom: $sp-16;

  > h2 {
    font-size: 20px;
    margin: 0;
    flex: 0 0 auto;
  }
}
