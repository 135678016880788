.coaching-session-confirmation-modal-info-highlight {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex: 1 1 0%;
  background-color: $color-mid-gray;
  padding: $sp-16;
  margin: $sp-16 0;
  border-radius: 8px;
}
