.create-new-invite-table {
  margin: 10px 0;

  .header-row {
    padding: $sp-16 0;
    background-color: $color-faint-gray;
  }

  .header-field {
    font-size: 14px;
    font-weight: bold;
    flex: 1;
  }

  .row {
    padding: 10px 6px;
    border-bottom: $light-grey-border;
  }

  .row-field {
    flex: 1;
  }
}
