.expand-width {
  min-width: 175px;
}

.furniture-v2-text-field {
  position: relative;
  display: flex;
  margin: $sp-8;

  input {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    height: 38px;
    background-color: $color-w-primary;
    outline: none;
    border: 1px solid $color-gray-80;
    border-radius: 5px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    width: 100%;
  }

  input:disabled {
    color: $color-disabled;
  }

  input[type='number'] {
    padding-right: 5px;
  }

  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
  }

  input:focus {
    box-shadow: 0 0 5px $color-blue;
    background-color: $color-blue-hover;
    border: 1px solid $color-blue;
  }

  input::placeholder {
    font-style: italic;
    color: $color-b-disabled;
  }

  &.v2-text-field-has-left-icon {
    input {
      text-indent: 36px;
    }

    svg {
      position: absolute;
      top: 9px;
      left: 14px;
      width: 20px;
      height: 20px;
    }
  }

  &.v2-text-field-has-right-icon {
    svg {
      position: absolute;
      top: 9px;
      right: 14px;
      width: 20px;
      height: 20px;
    }
  }
}

.furniture-v2-search-field {
  input {
    color: $color-b-secondary;
    background-color: $color-bg;
    border: none;

    &::placeholder {
      color: $color-b-disabled;
    }
  }

  input:focus {
    box-shadow: none;
    background-color: $color-bg;
    border: none;
  }
}
