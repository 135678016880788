.response-search-wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: $light-grey-border;

  .response-search-field-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    // Hide TextField underlines
    hr {
      display: none !important;
    }

    svg {
      flex: 1 1 auto;
      max-width: 24px;
      margin-right: 10px;
    }

    .response-search-field {
      flex: 2 1 auto;
      font-size: 18px !important;
      font-style: italic;
    }
  }
}

.feedback-search-chip {
  align-items: center;
  height: 48px;
  min-width: 144px;
  margin-top: $sp-56;
  padding: 6px $sp-16;
  border-radius: 8px;
}
