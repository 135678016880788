#access-warning {
  color: $color-redelete;
  font-style: italic;
}

.new-employee-form {
  @include wide(width, 60%);
  @include narrow(width, 90%);
}

.employee-departure-form {
  margin-top: $sp-20;
}

.employee-search {
  vertical-align: bottom;
}

.employee-table-title {
  font-family: 'Cornbread Honey' !important;
  font-weight: 600;
  font-size: $sp-20;
  margin: 0;
}

.employee-card-header-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $sp-8 $sp-8 $sp-8 15px !important;
  max-height: 96px;
  align-items: end !important;

  .employee-table-tabs {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 910px) {
  .employee-card-header-actions {
    max-height: none;
  }
}

.organization-limits {
  display: flex;

  > div {
    flex: 1;

    + div {
      margin-left: $sp-24;
    }
  }
}

.show-group-access {
  border-bottom: 1px dashed hsla(0, 0%, 0%, 0.25);
  cursor: pointer;
  position: relative;
}

.show-group-access-area {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.group-popover {
  max-width: 300px;
}

.see-all-link {
  color: $color-blue;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
}
