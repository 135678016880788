$drawer-width: 412px;
$sub-menu-width: 188px;
$sub-menu-item-width: 137px;

.drawer-v2-container {
  max-width: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  float: left;
  z-index: 1005;
  top: 0;
  height: 100vh;
  transition: max-width 500ms;
  min-height: -webkit-fill-available;

  .back-to-home-button {
    background-color: $color-tide-110;
    min-width: 40px !important;
    max-height: 40px !important;

    &:hover {
      background-color: $color-tide-120;
    }
  }

  .drawer-close {
    height: 18px;
    width: 18px;
    margin: $sp-8 0 $sp-16 $sp-8;
  }
  &.rebrand-drawer {
    overflow: visible;
    max-width: 0;

    .drawer-v2-logo {
      margin-top: $sp-4;
    }

    &.collapsed {
      .drawer-v2-main-nav {
        width: 64px;
      }
      .drawer-v2-sub-nav {
        width: 0;
      }

      .collapse-icon {
        transform: rotate(180deg);
      }
    }

    .collapse-icon {
      transition: transform 0.3s;
      transform: rotate(0);
    }
    .drawer-v2-nav {
      box-shadow: none;

      .drawer-v2-top-nav {
        padding: 0 0 $sp-16 0;
      }
    }

    .drawer-v2-logo-container {
      justify-content: left;
      margin-left: $sp-8;
    }

    .drawer-v2-main-nav {
      padding: $sp-8 $sp-8;
      border: 1px solid $color-tide-120;
      width: 224px;
    }
    .top-nav-menu-container,
    .bottom-nav-menu-container {
      overflow: hidden;
    }

    .drawer-v2-sub-nav {
      border: 1px solid $color-tide-120;
      border-left: none;
      box-shadow: none;
      width: $sub-menu-width;

      .drawer-sub-nav-header {
        border-bottom: 1px solid $color-tide-120;
        padding-bottom: $sp-16;
      }

      .drawer-v2-sub-menu {
        padding: 0 $sp-16;
      }
    }

    .custom-list-item {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0 0 $sp-8 0;
      height: $sp-40;
      border-radius: 8px;

      &.selected {
        background-color: $color-motion-100 !important;
        .custom-list-item-icon {
          svg g,
          svg path,
          svg circle,
          svg line,
          svg polyline {
            stroke: $color-deep-cerulean !important;
          }
        }

        .custom-list-item-label {
          color: $color-tide-200 !important;

          div {
            color: inherit !important;
          }
        }
      }

      .custom-list-item-icon {
        height: 40px;
        width: 48px;
        align-items: center;

        svg {
          margin: 0 $sp-16;
          height: 16px;
          width: 16px;
        }
      }

      .custom-list-item-label {
        color: $color-b-primary !important;
        font-weight: $font-normal !important;
        font-size: $sp-16;
        line-height: $sp-24;
        white-space: nowrap;
        padding: 0;
      }
      .org-switch-label {
        display: flex;
        flex-direction: column;
        justify-content: start;

        .org-switch-title {
          text-align: left;
          margin: 0;
        }

        .org-label {
          margin: 0;
          font-size: $sp-12;
          color: $color-tide-170;
        }
      }
    }
    .org-switch-item {
      height: 48px;
    }
  }
  .custom-list-item:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #f0f0f0;
    border: none;
  }

  .drawer-v2-nav {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-w-primary;
    overflow: auto;
    z-index: 3000;
    box-shadow: $color-b-12 3px 0px 6px;
    top: 0;

    .drawer-v2-top-nav {
      display: flex;
      justify-content: flex-start;
      position: relative;
      padding-top: $sp-16;
    }

    .drawer-v2-main-nav {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.drawer-v2-logo-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;
  padding: 0 0 $sp-8;
}

.drawer-v2-sub-menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 224px !important;
  top: 0;
  left: 65px;
  height: 100%;
  z-index: 2000;
  overflow: auto;
  box-shadow: $color-b-12 3px 0px 6px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &.is-hidden {
    left: 0px;
  }

  .drawer-v2-top-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 224px;
    height: 80px;
    color: $color-b-primary !important;
  }

  .drawer-v2-nav {
    background-color: $color-w-primary;
    overflow: visible;
  }
}

.org-switcher-dialog {
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 600px;
  min-width: 50%;

  .org-switcher-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $sp-20;
    border-bottom: 1px solid $color-drawer-border;

    .org-switcher-search {
      flex: 1;

      input {
        font-size: 16px;
      }
    }

    .org-switcher-include-trial-orgs {
      padding-top: $sp-20;
      display: flex;
      justify-content: space-between;
    }
  }

  .org-switcher-items-label {
    padding: $sp-20;
    background-color: $color-gray-background;
  }

  .org-switcher-items-container {
    display: flex;
    flex-direction: column;
    padding: $sp-8 $sp-24;
    overflow-y: auto;

    .org-switcher-menu-item {
      padding: $sp-20;
      font-size: 18px;
      border: none;
      border-radius: 10px;
      line-height: 24px;
      color: $color-b-60;
    }
  }
}

.org-switcher-switch-description {
  color: $color-stack-gray;
  margin: 0;
  font-size: 16px;
  line-height: 18px;
}

.top-nav-menu-container {
  overflow-y: auto;
}

.drawer-v2-container.manual-open {
  max-width: 298px;

  &.rebrand-drawer {
    max-width: $drawer-width;
  }
}

.drawer-brand-label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding: 0 $sp-16;
  white-space: nowrap;

  .drawer-brand-engage {
    font-size: 15px;
    line-height: 24px;
  }

  .drawer-brand-org {
    font-size: 14px;
    line-height: 20px;
  }
}

.drawer-tooltip {
  padding: $sp-8;
}

.empty-anchor-element {
  width: 0;
}

.drawer-popover {
  border-radius: 0.5rem;
  .collapsed-sub-menu-items {
    padding: $sp-8;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;

    div,
    a {
      width: 100%;
    }

    .v2-sub-menu-list-item {
      max-width: $sub-menu-item-width;
    }

    .v2-sub-menu-list-item-label div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: $sp-4;
    }
  }
}

@media screen and (min-width: $bp-xl) {
  .drawer-v2-container {
    position: sticky;
    max-width: 298px;
  }
}

@media screen and (min-width: $bp-md) {
  .drawer-v2-container {
    &.rebrand-drawer {
      position: sticky;
      max-width: $drawer-width;
    }
  }
}
